import { TYPE_TO_ICON_MAP } from "@/components/ui/icons";
import { RootState } from "@/store/store";
import { useAppSelector, useAppStore } from "@/store/store-hooks";
import { FaroMenuItem, FaroTooltip, blue, neutral } from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/foundation";
import { selectIElement } from "@faro-lotv/project-source";
import { useApiClientContext } from "@faro-lotv/service-wires";
import { useCallback, useState } from "react";
import { ContextMenuAction, NestedMenuObject } from "./action-types";

type ContextMenuActionItemProps = {
  /** The action to display in the context menu. */
  action: ContextMenuAction;

  /** The identifier of the element which the context menu is for. */
  elementId: GUID;

  /** Callback to execute when the action is clicked. */
  onContextMenuItemClicked(elementId: GUID, action: ContextMenuAction): void;

  /** Callback to execute when the context menu should be closed. */
  closeContextMenu(): void;

  /** Enable the dark version of the menu */
  dark?: boolean;
};

/**
 * @returns An entry in the context menu for a specific action.
 */
export function ContextMenuActionItem({
  action,
  elementId,
  onContextMenuItemClicked,
  closeContextMenu,
  dark,
}: ContextMenuActionItemProps): JSX.Element {
  const state = useAppStore().getState();
  const { projectApiClient: projectApi } = useApiClientContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const closeMenuAndCleanUp = useCallback(() => {
    setAnchorEl(null);
    closeContextMenu();
  }, [closeContextMenu]);

  const disabledMessage = useAppSelector(
    selectDisabledContextMenuActionMessage(action, elementId),
  );

  const [shouldShowNestedMenu, nestedMenuProps] = useAppSelector(
    selectShouldShowNestedMenu(action, elementId),
  );

  const tooltipMessage = useAppSelector(
    selectTooltipContextMenuActionMessage(action, elementId),
  );

  return (
    <FaroTooltip title={disabledMessage ?? tooltipMessage} placement="right">
      <span>
        <FaroMenuItem
          dark={dark}
          label={action.label}
          Icon={action.icon ? TYPE_TO_ICON_MAP[action.icon] : undefined}
          aria-label={action.label}
          disabled={!!disabledMessage}
          onClick={(ev) => {
            if (shouldShowNestedMenu) {
              setAnchorEl(ev.currentTarget);
              return;
            }
            // Prevent the label from receiving the event and selecting the element.
            ev.stopPropagation();
            onContextMenuItemClicked(elementId, action);
          }}
          sx={{
            mx: 1,
            px: 1,
            color: blue[500],
            ...(anchorEl && { backgroundColor: `${neutral[500]}1A` }),
          }}
        >
          {shouldShowNestedMenu &&
            nestedMenuProps?.options.map(
              ({ id, label, icon, tagline, onClick }) => (
                <FaroMenuItem
                  dark={dark}
                  Icon={icon ? TYPE_TO_ICON_MAP[icon] : undefined}
                  onClick={() => {
                    onClick({
                      nestedMenuItemId: id,
                      menuItemId: elementId,
                      state,
                      projectApi,
                    });
                    closeMenuAndCleanUp();
                  }}
                  key={label}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  label={label}
                  secondaryText={tagline}
                />
              ),
            )}
        </FaroMenuItem>
      </span>
    </FaroTooltip>
  );
}

/**
 * @param action The action to decide the disabled status for.
 * @param elementId The ID of the element to check the action for.
 * @returns A string if the action should be disabled, to be displayed as tooltip,
 * or `undefined` if the action should be enabled.
 */
function selectDisabledContextMenuActionMessage(
  action: ContextMenuAction,
  elementId: GUID,
) {
  return (state: RootState): string | undefined => {
    const iElement = selectIElement(elementId)(state);

    return iElement && action.disabledMessageForNode?.(iElement, state);
  };
}

/**
 * @param action The action to decide the tooltip message for.
 * @param elementId The ID of the element on which the action will be performed.
 * @returns A string to display as a tooltip for the action, or `undefined` if no tooltip should be displayed.
 */
function selectTooltipContextMenuActionMessage(
  action: ContextMenuAction,
  elementId: GUID,
) {
  return (state: RootState): string | undefined => {
    const iElement = selectIElement(elementId)(state);
    return iElement && action.tooltipMessage?.(iElement, state);
  };
}

/**
 * @param action The context menu action to perform.
 * @param elementId The ID of the element on which the action will be performed.
 * @returns A pair containing a boolean indicating if the nested menu should be shown, and the nested menu properties.
 */
function selectShouldShowNestedMenu(
  action: ContextMenuAction,
  elementId: GUID,
) {
  return (state: RootState): [boolean, NestedMenuObject | undefined] => {
    const nestedMenuProps =
      action.nestedMenuProps instanceof Function
        ? action.nestedMenuProps(elementId, state)
        : action.nestedMenuProps;
    const showNestedMenu =
      nestedMenuProps?.shouldShowNestedMenu?.(elementId, state) ?? false;
    return [showNestedMenu, nestedMenuProps];
  };
}
