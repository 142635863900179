import { ToSheetAnimation } from "@/components/r3f/animations/to-sheet-animation";
import { ModeTransitionProps } from "@/modes/mode";
import { useCurrentScene } from "@/modes/mode-data-context";
import { useState } from "react";

/**
 *  @returns The transition from a mode to this one
 *  @param props The properties provided by the App during the transition
 */
export function FloorScaleModeTransition(
  props: ModeTransitionProps,
): JSX.Element {
  const { activeSheets } = useCurrentScene();

  // Use the first visible layer for scaling.
  // https://faro01.atlassian.net/browse/CADBIM-1205 will later take all visible layers into account.
  const sheetForScaling = activeSheets[0];

  const [placeholders] = useState(() => []);
  return (
    <ToSheetAnimation
      placeholders={placeholders}
      sheetElement={sheetForScaling}
      {...props}
    />
  );
}
