import { useCached3DObjectIfReady } from "@/object-cache";
import { selectActiveCadModel } from "@/store/cad/cad-slice";
import {
  selectIsControlPointLayerAlignment,
  selectWizardElementToAlignId,
  selectWizardReferenceElementId,
} from "@/store/modes/alignment-wizard-mode-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { FaroStep } from "@faro-lotv/flat-ui";
import { isIElementModel3dStream } from "@faro-lotv/ielement-types";
import { selectIElement } from "@faro-lotv/project-source";
import { useMemo } from "react";
import { AlignmentStepper } from "../alignment-modes-commons/alignment-stepper";

interface AlignWizardProgressBarProps {
  /** A callback that is called when the user clicks "Next" button in the progressbar */
  goToNextStep(): void;
}

/**
 * @returns The progress bar that is shown at the top of an alignment Wizard mode
 *  Allows user to see where there are currently in the alignment process
 */
export function AlignWizardProgressBar({
  goToNextStep,
}: AlignWizardProgressBarProps): JSX.Element {
  const elementToAlignId = useAppSelector(selectWizardElementToAlignId);
  const referenceElementId = useAppSelector(selectWizardReferenceElementId);
  const isControlPointLayerAlignment = useAppSelector(
    selectIsControlPointLayerAlignment,
  );

  const referenceElement = useAppSelector(selectIElement(referenceElementId));

  const activeCad = useAppSelector(selectActiveCadModel);
  const cadModelObject = useCached3DObjectIfReady(activeCad);

  let isReferenceElementReady = isControlPointLayerAlignment;
  if (referenceElement && !isControlPointLayerAlignment) {
    // in case if reference element could be a cloud check if it present in cache before allowing go to actual alignment
    isReferenceElementReady = isIElementModel3dStream(referenceElement)
      ? !!cadModelObject
      : true;
  }

  const steps: FaroStep[] = useMemo(
    () => [
      {
        key: "1",
        label: "Select reference",
        allowNext: () => !!elementToAlignId && isReferenceElementReady,
      },
    ],
    [elementToAlignId, isReferenceElementReady],
  );

  return (
    <AlignmentStepper
      steps={steps}
      lastStepButtonText="Align"
      hideStepNumbers
      onLastStepButtonClicked={goToNextStep}
    />
  );
}
