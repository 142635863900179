import {
  ChangeActiveLayerProperties,
  EventType,
} from "@/analytics/analytics-events";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { selectIsSheetVisible } from "@/store/selections-selectors";
import { setActiveSheets, setSheetVisibility } from "@/store/selections-slice";
import { RootState } from "@/store/store";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectHasWritePermission } from "@/store/user-selectors";
import {
  convertToDateString,
  FaroIconButton,
  LayoutSheetSmallIcon,
  LocationMapIcon,
  NoTranslate,
  SingleSelectItem,
  SingleSelectItemProps,
  TranslateVar,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import {
  IElementAreaSection,
  IElementGenericDataset,
  IElementGenericImgSheet,
  isIElementAreaSection,
  isIElementGenericDataset,
} from "@faro-lotv/ielement-types";
import { selectAncestor } from "@faro-lotv/project-source";
import { Stack, SxProps, Theme } from "@mui/material";
import { createSelector } from "@reduxjs/toolkit";
import { useCallback, useMemo } from "react";
import { ElementIcon, ElementIconType } from "../icons";
import { ContextMenu } from "../tree/tree-context-menu/tree-context-menu";

type MultiLayerMenuItemProps = Pick<SingleSelectItemProps, "selectedValue"> & {
  /** The sheet of this layer */
  sheet: IElementGenericImgSheet;

  /** Method to call when an item is selected */
  onClick?(): void;
};

/** @returns the select item for each available layer */
export function MultiLayerMenuItem({
  sheet,
  onClick,
  ...rest
}: MultiLayerMenuItemProps): JSX.Element {
  const dispatch = useAppDispatch();
  const reference = useAppSelector((state) =>
    selectLayerReference(state, sheet),
  );

  const hasWritePermission = useAppSelector(selectHasWritePermission);

  const canManageLayersVisibility = useAppSelector(
    selectHasFeature(Features.LayersVisibility),
  );

  const trackAndHandleClick = useCallback(() => {
    if (!canManageLayersVisibility) {
      // Temporary keep legacy behavior when canManageLayersVisibility is not enabled
      // When canManageLayersVisibility is enabled, active sheets will be computed form visibility flags
      dispatch(setActiveSheets([sheet.id]));
    }

    const type = reference?.typeHint ?? sheet.typeHint;

    if (type) {
      Analytics.track<ChangeActiveLayerProperties>(
        EventType.changeActiveLayer,
        {
          type,
        },
      );
    }

    onClick?.();
  }, [onClick, reference, sheet, dispatch, canManageLayersVisibility]);

  const isVisible = useAppSelector((state) =>
    selectIsSheetVisible(state, sheet.id),
  );

  return (
    <SingleSelectItem
      {...rest}
      onClick={trackAndHandleClick}
      label={<NoTranslate>{sheet.name}</NoTranslate>}
      extraAction={
        <Stack direction="row">
          {canManageLayersVisibility && (
            <EyeButton
              isVisible={isVisible}
              onClick={() =>
                dispatch(
                  setSheetVisibility({
                    sheetId: sheet.id,
                    isVisible: !isVisible,
                  }),
                )
              }
            />
          )}
          {hasWritePermission && (
            <ContextMenu id={sheet.id} isNodeDisabled={false} dark />
          )}
        </Stack>
      }
      key={sheet.id}
      value={sheet.id}
      secondaryText={
        reference ? (
          <MultiLayerMenuItemSecondaryText reference={reference} />
        ) : undefined
      }
      dark
    />
  );
}

type MultiLayerMenuItemSecondaryTextProps = {
  /** The reference element the current layer is part of */
  reference: IElementGenericDataset | IElementAreaSection;
};

/** @returns the secondary text for a layer menu select item */
function MultiLayerMenuItemSecondaryText({
  reference,
}: MultiLayerMenuItemSecondaryTextProps): JSX.Element {
  const text = useMemo(() => {
    if (isIElementAreaSection(reference)) {
      return "Sheet";
    }
    return (
      <TranslateVar name="layerName">
        {convertToDateString(reference.createdAt)} {reference.name}
      </TranslateVar>
    );
  }, [reference]);

  return (
    <Stack alignItems="center" direction="row" gap={1}>
      <LayerIcon reference={reference} sx={{ fontSize: "1em" }} />
      {text}
    </Stack>
  );
}

type LayerIconProps = {
  /** The reference element for the layer */
  reference: IElementGenericDataset | IElementAreaSection;
  /** Custom style properties */
  sx?: SxProps<Theme>;
};

/** @returns the icon component for a layer */
function LayerIcon({ reference, sx }: LayerIconProps): JSX.Element | null {
  if (isIElementAreaSection(reference)) {
    return <LayoutSheetSmallIcon sx={sx} />;
  } else if (isIElementGenericDataset(reference)) {
    return <LocationMapIcon sx={sx} />;
  }
  return null;
}

/**
 * @param state the app state
 * @param sheet the sheet to compute the reference for
 * @returns the reference element for a layer (area or dataset)
 */
const selectLayerReference = createSelector(
  [
    (state: RootState, sheet: IElementGenericImgSheet) =>
      selectAncestor(sheet, isIElementGenericDataset)(state),
    (state: RootState, sheet: IElementGenericImgSheet) =>
      selectAncestor(sheet, isIElementAreaSection)(state),
  ],
  (dataSet, area) => dataSet ?? area,
);

type EyeButtonProps = {
  /** Method to call when an item is selected */
  onClick(): void;

  /** Flag used to show correct eye icon in menu: Visible/NonVisible  */
  isVisible: boolean;
};

/** @returns render eye button */
function EyeButton({ isVisible, onClick }: EyeButtonProps): JSX.Element {
  return (
    <FaroIconButton
      onClick={onClick}
      margin={0.5}
      size="s"
      aria-label="toggle layer visibility"
      // Get both the image color and its hover color from the container
      color="inherit"
      hoverColor="inherit"
      sx={{
        color: "inherit",
      }}
    >
      <ElementIcon
        icon={isVisible ? ElementIconType.Visible : ElementIconType.NonVisible}
      />
    </FaroIconButton>
  );
}
