import {
  EventType,
  SelectLocationProperties,
} from "@/analytics/analytics-events";
import { MapUserMarker } from "@/components/r3f/renderers/map-user-marker-renderer";
import {
  centerOrthoCamera,
  useCenterCameraOnPlaceholders,
} from "@/hooks/use-center-camera-on-placeholders";
import { obbToPlanes } from "@/hooks/use-clipping-planes";
import {
  CONTAINER_WIDTH_PERCENTAGE,
  CONTAINER_WIDTH_PERCENTAGE_HOVERED,
  useMinimapHeight,
} from "@/hooks/use-minimap-height";
import { useCurrentAreaClippingBox } from "@/hooks/use-object-bounding-box";
import { useCurrentScene } from "@/modes/mode-data-context";
import { SheetModeSceneBase } from "@/modes/sheet-mode/sheet-scene-base";
import { useCached3DObjectsIfExists } from "@/object-cache";
import {
  selectIsMinimapFullScreen,
  setMiniMapFullscreen,
} from "@/store/minimap-slice";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import {
  CameraAnimationTimeOptions,
  getCameraAnimationTime,
} from "@/utils/camera-animation-time";
import {
  ArrowDownIcon,
  FaroText,
  View,
  ViewDiv,
  findClosestIndex,
  neutral,
  parseVector3,
  selectChildrenDepthFirst,
  selectIElementWorldPosition,
  useNonExhaustiveEffect,
} from "@faro-lotv/app-component-toolbox";
import { NO_TRANSLATE_CLASS } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import {
  IElementGenericImgSheet,
  IElementImg360,
  IElementSection,
  isIElementImg360,
} from "@faro-lotv/ielement-types";
import { Box, Stack } from "@mui/material";
import { ThreeEvent, Vector3 as Vector3Prop } from "@react-three/fiber";
import {
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { Camera, Color, OrthographicCamera, Vector3 } from "three";
import { CameraAnimation } from "../animations/camera-animation";
import { AppColorBar } from "./app-color-bar";

/** Animation time to use for the minimap */
const ANIMATION_TIME: CameraAnimationTimeOptions = {
  min: 0.5,
  max: 1,
  scale: 10,
};

/** Minimum height of the minimap orthocamera frustum, when the minimap starts up */
const MIN_MINIMAP_CAMERA_HEIGHT = 28;

/** Actions that can be triggered on the minimap from outside the component */
export type MiniMapActions = {
  /** Reduce the minimap size from fullscreen to the default size */
  shrink(): void;
  /** Centers the minimap camera on a position */
  centerCameraOn(pos: Vector3): void;
};

type MiniMapProps = {
  /** Id of the ViewDiv this minimap need to track */
  trackingElement: HTMLDivElement;

  /** The canvas inside the minimap div */
  canvasElement?: HTMLCanvasElement | null;

  /** The sheet to render in the minimap */
  sheetElements: IElementGenericImgSheet[];

  /** The sheet whose elevation will be used */
  sheetElementForElevation?: IElementGenericImgSheet;

  /** The camera used to show the "user" position on the minimap */
  camera: Camera;

  /** The camera used to show the "user" position on the minimap in second screen (used in split-screen view) */
  secondScreenCamera?: Camera;

  /** The desired camera position, if changed will animate the current camera to this position */
  cameraPosition: Vector3Prop;

  /** True to show the user marker */
  showUserMarker?: boolean;

  /** True if the view cone of the user marker should be visible @default false */
  shouldShowViewCone?: boolean;

  /** True if the view cone related to view in second split-screen should be visible @default false */
  shouldShowViewConeSecondCamera?: boolean;

  /** The starting zoom level of the minimap as a percentage of the bounds of the placeholders */
  zoom?: number;

  /** A handle on actions that can be executed on the minimap */
  actions?: RefObject<MiniMapActions>;

  /** Callback when the minimap is clicked which gives the clicked position */
  onMinimapClicked?(pos: Vector3): void;

  /** Callback to signal a placeholder have been clicked*/
  onPlaceholderClicked?(target: IElementImg360): void;
};

/**
 * @returns A component to render a mini map
 */
export function MiniMap({
  trackingElement,
  canvasElement,
  camera,
  cameraPosition,
  secondScreenCamera,
  sheetElements,
  sheetElementForElevation,
  showUserMarker,
  shouldShowViewCone = false,
  shouldShowViewConeSecondCamera = false,
  actions,
  zoom = 0.3,
  onMinimapClicked,
  onPlaceholderClicked,
}: MiniMapProps): JSX.Element {
  const { activeSheets, panos, paths, referenceElement } = useCurrentScene();

  // Use the first visible sheet to set the minimap orientation.
  // https://faro01.atlassian.net/browse/CADBIM-1205 will later take all visible layers into account.
  const singleSheetForMinimap = activeSheets[0];

  const cameraData = useCenterCameraOnPlaceholders({
    sheetElement: singleSheetForMinimap,
    placeholders: panos,
    paddingFactor: zoom,
    cameraPosition,
    minFrustumHeight: MIN_MINIMAP_CAMERA_HEIGHT,
  });

  // Create custom ortho camera
  const [minimapCamera] = useState(() => {
    const minimapCamera = new OrthographicCamera();
    centerOrthoCamera(minimapCamera, cameraData);
    return minimapCamera;
  });

  // Target position for the animations
  const [cameraTarget, setCameraTarget] = useState<Vector3>();

  // The sheet mode currently only clips using an area's volume, since the user has no way to edit a custom one.
  const areaBox = useCurrentAreaClippingBox();
  const clippingPlanes = useMemo(
    () => (areaBox ? obbToPlanes(areaBox) : undefined),
    [areaBox],
  );

  const dispatch = useAppDispatch();

  useImperativeHandle(actions, () => ({
    shrink() {
      dispatch(setMiniMapFullscreen(false));
    },
    centerCameraOn(pos: Vector3) {
      if (!cameraTarget) {
        pos.y = minimapCamera.position.y;
        setCameraTarget(pos);
      }
    },
  }));

  // If the cameraPosition prop changes move the camera
  useEffect(() => {
    if (cameraPosition) {
      const pos = parseVector3(cameraPosition);
      // Keep the camera height to the same level we computed at the start when we move
      pos.y = minimapCamera.position.y;
      setCameraTarget(pos);
    }
    // We want to move the camera only when the prop changes, not every time the camera changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraPosition]);

  const store = useAppStore();

  const triggerClickOnCloserPlaceholder = useCallback(
    (ev: ThreeEvent<MouseEvent>, path: IElementSection) => {
      ev.stopPropagation();
      if (!onPlaceholderClicked) return;
      const panos = selectChildrenDepthFirst(
        path,
        isIElementImg360,
      )(store.getState());
      const panoPositions = panos.map((pano) =>
        new Vector3().fromArray(
          selectIElementWorldPosition(pano.id)(store.getState()),
        ),
      );
      const closest = findClosestIndex(ev.point, panoPositions);
      if (closest) {
        onPlaceholderClicked(panos[closest]);
      }
    },
    [onPlaceholderClicked, store],
  );

  const sheetObjects = useCached3DObjectsIfExists(sheetElements);

  return (
    <View
      trackingElement={trackingElement}
      canvasElement={canvasElement}
      camera={minimapCamera}
      hasSeparateScene
      background={new Color("white")}
    >
      {cameraTarget && (
        <CameraAnimation
          position={cameraTarget}
          onAnimationFinished={() => setCameraTarget(undefined)}
          duration={getCameraAnimationTime(
            camera,
            cameraPosition,
            ANIMATION_TIME,
          )}
        />
      )}
      <SheetModeSceneBase
        sheets={sheetObjects}
        sheetElementForElevation={sheetElementForElevation}
        pathElement={referenceElement}
        paths={paths}
        panos={panos}
        clippingPlanes={clippingPlanes}
        isInMinimap
        onSheetClick={onMinimapClicked}
        onPlaceholderClicked={(e) => {
          Analytics.track(EventType.selectLocation, {
            via: SelectLocationProperties.minimap,
          });

          onPlaceholderClicked?.(e);
        }}
        onPathActivated={triggerClickOnCloserPlaceholder}
      />
      {showUserMarker && (
        <>
          <MapUserMarker
            userCamera={camera}
            sheetForElevation={sheetElementForElevation}
            shouldShowViewCone={shouldShowViewCone}
          />
          {secondScreenCamera && (
            <MapUserMarker
              userCamera={secondScreenCamera}
              sheetForElevation={sheetElementForElevation}
              shouldShowViewCone={shouldShowViewConeSecondCamera}
            />
          )}
        </>
      )}
    </View>
  );
}

export type MiniMapOverlayProps = {
  /** A ref to the div to use as a tracking element for the MiniMap */
  eventDivRef: RefObject<HTMLDivElement>;

  /** The reference to the canvas inside the MiniMap div */
  canvasRef?: RefObject<HTMLCanvasElement>;

  /** Name of the sheet currently selected */
  activeSheetName?: string;

  /** Whether the minimap should be always minimized, for example because the picking tools are active */
  forceMinimized: boolean;
};

/**
 * @returns An overlay with the minimap functions to use in pair with MiniMap
 */
export function MiniMapOverlay({
  eventDivRef,
  canvasRef,
  activeSheetName,
  forceMinimized,
}: MiniMapOverlayProps): JSX.Element {
  const dispatch = useAppDispatch();

  const [isMinimized, setIsMinimized] = useState(forceMinimized);
  const [isContainerHovered, setIsContainerHovered] = useState(false);
  const [isFloorPlanLabelHovered, setIsFloorPlanLabelHovered] = useState(false);
  const isFullScreen = useAppSelector(selectIsMinimapFullScreen);

  // Using a state instead of a reference to be sure that the useEffects that depend
  // on this element are mounted properly
  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  function handleToggleMinimized(): void {
    // If the user is measuring or annotating, clicks on the minimized minimap should not have effect
    if (forceMinimized) return;
    // Toggle the 'minimized' prop
    setIsMinimized(!isMinimized);
    if (!isMinimized) {
      setIsFloorPlanLabelHovered(false);
      setIsContainerHovered(false);
    }
    dispatch(setMiniMapFullscreen(false));
  }

  // This hook ensures that the minimap is minimized if forcedMinimize is true
  // if forceMinimized is set to false again the previous status of the minimap is restored
  useNonExhaustiveEffect(() => {
    if (forceMinimized) {
      const wasMinimized = isMinimized;
      setIsMinimized(true);
      dispatch(setMiniMapFullscreen(false));
      return () => {
        setIsMinimized(wasMinimized);
        if (!wasMinimized) {
          setIsFloorPlanLabelHovered(false);
          setIsContainerHovered(false);
        }
      };
    }
  }, [forceMinimized]);

  const canvasHeight = useMinimapHeight({
    container,
    isContainerHovered,
    isMinimized,
    isFloorPlanLabelHovered,
    isFullScreen,
  });

  return (
    <Box
      // This container is only used to get the value in pixels of 40% of the width
      ref={setContainer}
      component="div"
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        pointerEvents: "none",
      }}
    >
      <Stack
        alignItems="stretch"
        sx={{
          // Place the minimap in the bottom right
          position: "absolute",
          right: 0,
          bottom: 0,
          // Add some margin so that it's not exactly at the corner
          margin: 1,
          outline: `1px solid ${neutral[0]}33`,
          borderRadius: "4px",
          backgroundColor: neutral[999],
          padding: 0.5,
          // Make the minimap a bit bigger on hover
          width: isContainerHovered
            ? `${CONTAINER_WIDTH_PERCENTAGE_HOVERED}%`
            : `${CONTAINER_WIDTH_PERCENTAGE}%`,
          transition: "width 1s",
          // If a tool like the createAnnotation is enabled, we do not allow to open, click or hover the minimap
          pointerEvents: forceMinimized ? "none" : "auto",
        }}
        onPointerEnter={() => setIsContainerHovered(true)}
        onPointerLeave={() => setIsContainerHovered(false)}
      >
        <Box
          component="div"
          sx={{
            height: canvasHeight,
            // Position is set to relative so that the children can be positioned absolutely but inside the parent
            position: "relative",
            overflow: "hidden",
            transition: "height 1s",
          }}
        >
          <Stack
            direction="row"
            sx={{
              // Use position absolute so that the content can be shown over the parent's padding
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            gap={0.5}
          >
            <MinimapCanvas
              eventDivRef={eventDivRef}
              canvasRef={canvasRef}
              showExpandButton={!isMinimized}
              isFullScreen={isFullScreen}
            />
            <AppColorBar />
          </Stack>
        </Box>
        <Stack
          justifyContent="space-between"
          alignItems="center"
          direction="row"
          aria-label="minimap open close"
          sx={{
            width: "100%",
            backdropFilter: "blur(1px)",
            // Add some padding to the text only if the minimap is minimized, so that the border remains 4px
            pt:
              isMinimized && !isContainerHovered && !isFloorPlanLabelHovered
                ? 0
                : 0.5,
            cursor: "pointer",
            transition: "padding 1s",
          }}
          onPointerEnter={() => setIsFloorPlanLabelHovered(!forceMinimized)}
          onPointerLeave={() => setIsFloorPlanLabelHovered(false)}
          onClick={handleToggleMinimized}
        >
          <FaroText
            variant="heading14"
            sx={{ textOverflow: "ellipsis" }}
            className={NO_TRANSLATE_CLASS}
            dark
            noWrap
          >
            {activeSheetName}
          </FaroText>
          <ArrowDownIcon
            sx={{
              color: neutral[100],
              width: "16px",
              height: "16px",
              ...(isMinimized && { transform: "rotate(180deg)" }),
            }}
          />
        </Stack>
      </Stack>
    </Box>
  );
}

type MinimapCanvasProps = Pick<
  MiniMapOverlayProps,
  "eventDivRef" | "canvasRef"
> & {
  showExpandButton: boolean;
  isFullScreen: boolean;
};

function MinimapCanvas({
  canvasRef,
  eventDivRef,
}: MinimapCanvasProps): JSX.Element {
  return (
    <Box
      component="div"
      sx={{ position: "relative", width: "100%", height: "100%" }}
    >
      <Box
        component="canvas"
        ref={canvasRef}
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          pointerEvents: "none",
          borderRadius: 1,
        }}
      />
      <ViewDiv
        eventDivRef={eventDivRef}
        sx={{ position: "absolute", width: "100%", height: "100%" }}
      >
        {/** TODO: Implement full screen and full screen animation */}
        {/** https://faro01.atlassian.net/browse/SWEB-4987 */}
        {/*
        {showExpandButton && (
          <MinimapFullScreenBtn
            isFullScreen={isFullScreen}
            sx={{ position: "absolute", bottom: 0, right: 0 }}
          />
        )} */}
      </ViewDiv>
    </Box>
  );
}
