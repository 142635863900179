import { selectAlignmentArea } from "@/alignment-tool/store/alignment-selectors";
import { assert } from "@faro-lotv/foundation";
import {
  GUID,
  IElement,
  IElementAreaSection,
  IElementGenericImgSheet,
  IElementImg360,
  IElementMarkup,
  IElementSection,
  IElementType,
  IElementTypeHint,
  MarkupIElement,
  WithHint,
  isIElementAreaSection,
  isIElementGenericAnnotation,
  isIElementGenericDataset,
  isIElementGenericImgSheet,
  isIElementImg360,
  isIElementMarkup,
  isIElementOdometryPath,
  isIElementPanoInOdometryPath,
  isIElementSection,
} from "@faro-lotv/ielement-types";
import {
  selectAncestor,
  selectChildDepthFirst,
  selectChildrenDepthFirst,
  selectIElement,
  selectIElementWorldPosition,
} from "@faro-lotv/project-source";
import { createSelector } from "@reduxjs/toolkit";
import { Vector3 } from "three";
import { Features, selectHasFeature } from "./features/features-slice";
import { RootState } from "./store";

/**
 * @param state Current state
 * @returns The IElement of the current selection
 */
export function selectActiveElement(state: RootState): IElement | undefined {
  if (!state.selections.activeElement) {
    return;
  }
  return selectIElement(state.selections.activeElement)(state);
}

/**
 * @param state Current state
 * @returns The IElement of the current selection
 */
export function selectActiveElementId(state: RootState): GUID | undefined {
  return state.selections.activeElement;
}

/**
 * @param state Current state
 * @returns true if the project is empty (does not contain an Area and an ImgSheet)
 */
export function selectIsProjectEmpty(state: RootState): boolean {
  return !selectActiveArea(state);
}

/**
 * @param id An Img360 ID or a Section ID that contains an Img360
 * @returns The relevant Img360 and section for the passed id
 */
export function selectPanoAndSection(id: GUID | undefined) {
  return (
    state: RootState,
  ): {
    section: IElementSection | undefined;
    img360: IElementImg360 | undefined;
  } => {
    if (!id) {
      return { section: undefined, img360: undefined };
    }

    const startingElement = selectIElement(id)(state);
    const section = selectAncestor(startingElement, isIElementSection)(state);
    if (!section) {
      throw Error(
        "Passed ID is not an Img360 or a Section containing an Img360",
      );
    }

    const img360 = selectChildDepthFirst(section, isIElementImg360, 1)(state);
    if (!img360) {
      throw Error(
        "Passed ID is not an Img360 or a Section containing an Img360",
      );
    }

    return { section, img360 };
  };
}

/**
 * @returns The closest pano amongst the children of the provided reference element
 * @param referenceId Reference element of the children to check for
 * @param referencePos Position to use as reference
 */
export function selectClosestPanoInSpaceAmongstChildren(
  referenceId: GUID,
  referencePos: Vector3,
) {
  return (state: RootState): IElementImg360 | undefined => {
    const referenceElement = selectIElement(referenceId)(state);

    const panos = selectChildrenDepthFirst(
      referenceElement,
      isIElementImg360,
    )(state);

    return selectClosestPanoToPosition(panos, referencePos)(state);
  };
}

/**
 * @returns The closest pano to the provided position (in space)
 * @param panos The pano elements to check
 * @param position The position to find the closes pano for
 */
export function selectClosestPanoToPosition(
  panos: IElementImg360[],
  position: Vector3,
) {
  return (state: RootState): IElementImg360 | undefined => {
    let closestPano: IElementImg360 | undefined;
    let closestDistance: number = Infinity;

    const pos = new Vector3();

    for (const pano of panos) {
      pos.fromArray(selectIElementWorldPosition(pano.id)(state));
      const distance = pos.distanceTo(position);
      if (distance < closestDistance) {
        closestDistance = distance;
        closestPano = pano;
      }
    }

    return closestPano;
  };
}

/**
 * @param state Current app state
 * @returns The Area (child or parent) related to this element or undefined if no area is available
 */
export function selectActiveArea(
  state: RootState,
): IElementSection | undefined {
  if (!state.selections.activeArea) return;

  const area = state.iElements.iElements[state.selections.activeArea];
  if (area && isIElementSection(area)) return area;
}

/**
 * @param element to select the area for
 * @returns the area linked to an element, or undefined if the element is not linked to an area
 */
export function selectAreaFor(element?: IElement) {
  return (state: RootState): IElementAreaSection | undefined => {
    // Pick the parent area of the element if the element is in the BI tree
    const area = selectAncestor(element, isIElementAreaSection)(state);
    if (area) {
      return area;
    }

    // Pick the area from the first alignment edge for elements in the Capture Tree
    const dataSet = selectAncestor(element, isIElementGenericDataset)(state);
    const targetAreaId =
      dataSet &&
      Object.entries(state.iElements.areaDataSets).find(([, dataSets]) =>
        dataSets?.some((value) => value.elementId === dataSet.id),
      )?.[0];
    const targetArea = targetAreaId
      ? selectIElement(targetAreaId)(state)
      : undefined;

    assert(
      !targetArea || isIElementAreaSection(targetArea),
      "A dataset should be linked to a Section(Area)",
    );
    return targetArea;
  };
}

/**
 * @param reference The element whose area section we are looking for, or the active element
 * @returns The Area Section (child or parent) related to this element, OR the area currently used for alignment
 * @throws { Error } if no area section is available
 */
export function selectActiveAreaOrThrow(reference?: IElement) {
  return (state: RootState): IElementSection => {
    const useNewAreaNavigation = selectHasFeature(Features.AreaNavigation)(
      state,
    );

    let areaSection: IElementSection | undefined = undefined;

    if (useNewAreaNavigation) {
      // TODO: With the new area navigation, logic should not expect there to always be an active area.
      // https://faro01.atlassian.net/browse/SWEB-5989
      const activeArea = selectActiveArea(state);
      const areaForReference = reference
        ? selectAreaFor(reference)(state)
        : undefined;
      areaSection = areaForReference ?? activeArea;
    } else {
      const alignmentArea = selectAlignmentArea(state);
      const activeEl = selectActiveElement(state);

      areaSection =
        alignmentArea ?? selectAreaFor(reference ?? activeEl)(state);
    }

    if (!areaSection) {
      throw new Error("No area section is available");
    }
    return areaSection;
  };
}

/**
 * @returns the list of markups for the given iElement
 * @param reference An IElement whose children are going to be searched for markups,
 * it can also be an Img360, in that case its sibling tree is checked for markups
 */
export function selectMarkups(reference?: IElement) {
  return (state: RootState): IElementMarkup[] | undefined => {
    if (!reference) return;
    const parent = isIElementImg360(reference)
      ? selectIElement(reference.parentId ?? "")(state)
      : reference;
    return selectChildrenDepthFirst(parent, isIElementMarkup)(state);
  };
}

/**
 * @param pano reference pano inside an OdometryPath Section
 * @returns The panos in video mode trajectory relative to the reference element
 */
export function selectAllPanosInOdometryPath(
  pano: WithHint<
    IElementImg360,
    | IElementTypeHint.odometryPath
    | IElementTypeHint.poiHighRes
    | IElementTypeHint.poiLowRes
    | IElementTypeHint.poiStandard
    | IElementTypeHint.poi
    | IElementTypeHint.flash
  >,
) {
  return (state: RootState): IElementImg360[] => {
    if (!pano.parentId) {
      return [];
    }
    const path = selectAncestor(pano, isIElementOdometryPath)(state);

    if (!path) return [];

    return selectChildrenDepthFirst(path, isIElementPanoInOdometryPath)(state);
  };
}

/**
 * @param pano to get the section containing its annotation
 * @returns the project Section node containing the annotations for this Pano
 */
export function selectPanoAnnotationSection(pano: IElementImg360) {
  return (state: RootState): IElement | undefined =>
    pano.typeHint === IElementTypeHint.odometryPath && pano.targetId
      ? selectIElement(pano.targetId)(state)
      : selectAncestor(pano, isIElementSection)(state);
}

/**
 * @returns the project Section node containing the annotations for this Pano
 * @param element element where the annotation was taken on by the user
 * @param area the current area that is used as a fallback if no other section is found
 */
export function selectAnnotationSection(
  element: IElement,
  area?: IElementSection,
) {
  return (state: RootState): IElement | undefined => {
    // Get the section that is the direct parent of the group containing the annotations:
    // it's a Pano Section if the target element is a 360, otherwise it's either the containing dataset or the area
    if (isIElementImg360(element)) {
      return selectPanoAnnotationSection(element)(state);
    }
    // If the element is in a dataset, the annotation should be saved there
    return selectAncestor(element, isIElementGenericDataset)(state) ?? area;
  };
}

/**
 * @param markup markup to whose attachments are to be returned
 * @returns list of attachments for the given markup
 */
export function selectMarkupAttachments(markup: MarkupIElement) {
  return (state: RootState): IElement[] => {
    const model3d = selectAncestor(markup, isIElementGenericAnnotation)(state);
    return selectChildrenDepthFirst(
      model3d,
      (el: IElement) =>
        el.typeHint === IElementTypeHint.command ||
        el.type === IElementType.attachment,
    )(state);
  };
}

/**
 * @returns the list of active sheets (memoized = will return the same list if the activeSheets and iElements arrays have not changed)
 * @param state the app state
 * DESIGN NOTES
 * The selector will only return the available sheets. But the returned type is still Array<IElementGenericImgSheet | undefined>
 * as this is the only way for typescript to assume returnedValue[x] might return undefined if x is an invalid index.
 */
export const selectActiveSheetsIfAvailable = createSelector(
  [
    (state: RootState) => state.selections.activeSheets,
    (state: RootState) => state.iElements.iElements,
  ],
  (activeSheets, iElements): Array<IElementGenericImgSheet | undefined> => {
    if (activeSheets.length) {
      return activeSheets
        .map((sheet) => {
          const element = iElements[sheet];
          return isIElementGenericImgSheet(element) ? element : undefined;
        })
        .filter((sheet) => sheet !== undefined);
    }
    return [];
  },
);

/**
 * @returns the list of active sheets or throw an error if one of them is not available
 * (memoized = will return the same list if the activeSheets and iElements arrays have not changed)
 * @param state the app state
 * The selector will only return available sheets. But the returned type is still Array<IElementGenericImgSheet | undefined>
 * as this is the only way for typescript to assume returnedValue[x] might return undefined if x is an invalid index.
 */
export const selectActiveSheets = createSelector(
  [
    (state: RootState) => state.selections.activeSheets,
    (state: RootState) => state.iElements.iElements,
  ],
  (activeSheets, iElements): Array<IElementGenericImgSheet | undefined> => {
    assert(activeSheets.length, "At least one active sheet should exist");

    const listActiveSheets = activeSheets
      .map((sheet) => {
        const element = iElements[sheet];
        return isIElementGenericImgSheet(element) ? element : undefined;
      })
      .filter((sheet) => sheet !== undefined);
    assert(
      listActiveSheets.length === activeSheets.length,
      "One of active sheet is an unsupported format",
    );

    return listActiveSheets;
  },
);

/**
 * @returns whether this layer is visible or not
 * @param state the app state
 * @param sheetId GUID of the layer/sheet to be tested
 * DESIGN NOTES
 * The selector is memoized for better performance.
 */
export const selectIsSheetVisible = createSelector(
  [
    (state: RootState) => state.selections.visibleSheets,
    (state: RootState, sheetId: GUID) => sheetId,
  ],
  (visibleSheets, sheetId): boolean =>
    visibleSheets ? !!visibleSheets[sheetId] : false,
);
