import { useViewOverlayRef } from "@/hooks/use-view-overlay-ref";
import { useInterval } from "@faro-lotv/app-component-toolbox";
import { blue, NoTranslate } from "@faro-lotv/flat-ui";
import { IElementImg360 } from "@faro-lotv/ielement-types";
import { useThree } from "@react-three/fiber";
import {
  MouseEvent,
  MutableRefObject,
  useCallback,
  useMemo,
  useState,
} from "react";
import { shallowEqual } from "react-redux";
import { Camera, Frustum, OrthographicCamera, Vector3 } from "three";
import { useWaypointReference } from "../utils/placeholder-preview";
import { TextLabel } from "./text-label";

/** The maximum number of visible waypoints to display labels in 2D view */
const MAX_NUMBER_OF_LABELS_2D_VIEW = 100;
/** The maximum number of visible waypoints to display labels in 3D view */
const MAX_NUMBER_OF_LABELS_3D_VIEW = 20;
/** The 'wayPointLabels' state is updated once 0.2 second */
const STATE_UPDATE_INTERVAL = 200;

export type WaypointPosition = {
  /** The waypoint to show */
  pano: IElementImg360;

  /** The location in world space where waypoint will be rendered */
  renderPosition: Vector3;
};

type WaypointLabelRenderProps = {
  /** The list of WaypointPositions */
  waypoints: WaypointPosition[];

  /** Callback to signal a waypoint label have been clicked */
  onLabelClick?(element: IElementImg360): void;
};

/**
 * @returns waypoint labels
 */
export function WaypointLabelRender({
  waypoints,
  onLabelClick,
}: WaypointLabelRenderProps): JSX.Element | null {
  const labelContainer = useViewOverlayRef();
  const camera = useThree((s) => s.camera);

  const [waypointLabels, setWaypointLabels] = useState<WaypointPosition[]>([]);
  useInterval(() => {
    const frustum = new Frustum();
    frustum.setFromProjectionMatrix(camera.projectionMatrix);
    frustum.planes.forEach((plane) => {
      plane.applyMatrix4(camera.matrixWorld);
    });
    const waypointsInView = waypoints.filter((waypoint) =>
      frustum.containsPoint(waypoint.renderPosition),
    );

    let newLabels;
    if (camera instanceof OrthographicCamera) {
      newLabels =
        waypointsInView.length > MAX_NUMBER_OF_LABELS_2D_VIEW
          ? []
          : waypointsInView;
    } else {
      newLabels = findClosestWaypoints(waypointsInView, camera);
    }

    if (!shallowEqual(waypointLabels, newLabels)) {
      setWaypointLabels(newLabels);
    }
  }, STATE_UPDATE_INTERVAL);

  if (waypointLabels.length === 0) {
    return null;
  }

  return (
    <>
      {waypointLabels.map((waypoint) => (
        <WaypointLabel
          key={waypoint.pano.id}
          pano={waypoint.pano}
          position={waypoint.renderPosition}
          parentRef={labelContainer}
          onLabelClick={onLabelClick}
        />
      ))}
    </>
  );
}

type WaypointLabelProps = {
  /** The panorama to show waypoint label */
  pano: IElementImg360;
  /** The location in world space where way point will be shown */
  position: Vector3;
  /** The parent that the label should have in the html DOM */
  parentRef: MutableRefObject<HTMLElement>;
  /** Callback to signal the waypoint label have been clicked */
  onLabelClick?(element: IElementImg360): void;
  /** True to disable pointer events on the label and always show full label */
  disablePointerEvents?: boolean;
};

const MAX_LABEL_LENGTH = 9;
/**
 * @returns a Html label that displays waypoint label and moves in the 3D scene along with a given 3D position.
 */
export function WaypointLabel({
  pano,
  position,
  parentRef,
  onLabelClick,
  disablePointerEvents = false,
}: WaypointLabelProps): JSX.Element {
  const referenceElement = useWaypointReference(pano);

  // truncate name in the middle if it is too long, use truncated as shortLabel and keep the full name as the fullLabel
  const { shortLabel, fullLabel } = useMemo(() => {
    const fullLabel = referenceElement?.name ?? pano.name;
    const shortLabel =
      fullLabel.length > MAX_LABEL_LENGTH
        ? `${fullLabel.slice(0, 3)}...${fullLabel.slice(fullLabel.length - 3)}`
        : fullLabel;
    return { shortLabel, fullLabel };
  }, [pano.name, referenceElement]);

  const [hovered, setHovered] = useState<boolean>();
  const onClick = useCallback(
    (ev: MouseEvent<HTMLDivElement>) => {
      ev.stopPropagation();
      if (onLabelClick) {
        onLabelClick(pano);
      }
    },
    [onLabelClick, pano],
  );

  return (
    <TextLabel
      index={0}
      position={position}
      parentRef={parentRef}
      visible
      active
      activeCursor="pointer"
      transparent
      pointerEvents={disablePointerEvents ? "none" : "auto"}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
      placement="bottom"
      backgroundColor={blue[800]}
      padding={0.25}
      fontSize="0.625em"
    >
      <NoTranslate>
        {hovered || disablePointerEvents ? fullLabel : shortLabel}
      </NoTranslate>
    </TextLabel>
  );
}

/**
 * @param waypoints the given waypoints from which to find the MAX_NUMBER_OF_LABELS_3D_VIEW waypoints that are closest to a given camera
 * @param camera the given camera
 * @returns the MAX_NUMBER_OF_LABELS_3D_VIEW waypoints that are closest to the camera
 */
function findClosestWaypoints(
  waypoints: WaypointPosition[],
  camera: Camera,
): WaypointPosition[] {
  if (waypoints.length <= MAX_NUMBER_OF_LABELS_3D_VIEW) {
    return waypoints;
  }

  waypoints.sort(
    (point1, point2) =>
      point1.renderPosition.distanceTo(camera.position) -
      point2.renderPosition.distanceTo(camera.position),
  );

  return waypoints.slice(0, MAX_NUMBER_OF_LABELS_3D_VIEW);
}
