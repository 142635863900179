import { matrix4ToAlignmentTransform } from "@/alignment-tool/utils/alignment-transform";
import {
  setControlPointsReferencePoint1,
  setControlPointsReferencePoint2,
  setControlPointsSheetElevation,
} from "@/store/modes/control-points-alignment-mode-slice";
import { setSheetElevation } from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import { FaroText, TextField, TruncatedFaroText } from "@faro-lotv/flat-ui";
import { GUID, isIElementAreaSection } from "@faro-lotv/ielement-types";
import { selectAncestor, selectIElement } from "@faro-lotv/project-source";
import { Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Vector3Tuple } from "three";

type ControlPointsAlignmentSetPointsPanelProps = {
  /** id of area or layer to align */
  layerOrAreaId: GUID;
};

/**
 * @returns cad alignment set elevation panel
 */
export function ControlPointsAlignmentSetPointsPanel({
  layerOrAreaId,
}: ControlPointsAlignmentSetPointsPanelProps): JSX.Element {
  const dispatch = useAppDispatch();
  const store = useAppStore();

  const elementToAlign = selectIElement(layerOrAreaId)(store.getState());
  if (!elementToAlign) {
    throw new Error("Element to align not assigned");
  }

  const alignedLayerWorldMatrix = selectIElementWorldMatrix4(layerOrAreaId)(
    store.getState(),
  );

  const alignedLayerTransform = matrix4ToAlignmentTransform(
    alignedLayerWorldMatrix,
  );

  const parentArea = useAppSelector(
    selectAncestor(elementToAlign, isIElementAreaSection),
  );

  const sectionArea = isIElementAreaSection(elementToAlign)
    ? elementToAlign
    : parentArea;

  if (!sectionArea) {
    throw new Error("Section Area not found for selected sheet.");
  }

  // Define the state variables for the text field
  const [elevationErrorText, setElevationErrorText] = useState("");
  const [elevationTextField, setElevationTextField] = useState(
    alignedLayerTransform.position[1].toString(),
  );

  const changeReferencePoint1 = useCallback(
    (position?: Vector3Tuple) => {
      dispatch(setControlPointsReferencePoint1(position));
    },
    [dispatch],
  );
  const changeReferencePoint2 = useCallback(
    (position?: Vector3Tuple) => {
      dispatch(setControlPointsReferencePoint2(position));
    },
    [dispatch],
  );

  return (
    <Stack
      spacing={3}
      sx={{
        mt: "5px",
        ml: "10px",
        mr: "10px",
        width: 320,
      }}
    >
      <TruncatedFaroText
        variant="bodyM"
        sx={{
          pt: "15px",
          fontSize: "18px",
          fontWeight: 600,
        }}
      >
        Control points
      </TruncatedFaroText>

      <FaroText
        variant="bodyM"
        sx={{
          fontSize: "12px",
        }}
      >
        Add 2 control points and specify their coordinates
      </FaroText>

      <TextField
        fullWidth
        label="Elevation (m)"
        sx={{ height: "35px" }}
        text={elevationTextField}
        error={elevationErrorText}
        onTextChanged={(newText) => {
          setElevationTextField(newText);
          const newElevation = Number(newText);
          if (isNaN(newElevation)) {
            setElevationErrorText("Elevation must be a number");
            dispatch(setSheetElevation(undefined));
          } else {
            setElevationErrorText("");
            if (Number(elevationTextField) !== newElevation) {
              dispatch(setControlPointsSheetElevation(newElevation));
            }
          }
        }}
      />

      <TwoCoordinatesControl
        title="Point 1 coordinates (m)"
        changeReferencePoint={changeReferencePoint1}
      />
      <TwoCoordinatesControl
        title="Point 2 coordinates (m)"
        changeReferencePoint={changeReferencePoint2}
      />
    </Stack>
  );
}

type CoordinateEditControlProps = {
  /** label (x,y,z) to show left to edit control */
  label: string;

  /** error message text if input is not correct */
  errorText?: string;

  /** method to send error message to parent component */
  setErrorText(text: string): void;

  /** method to send updated coordinate value to parent component */
  setNewValue(value?: number): void;
};

function CoordinateEditControl({
  label,
  errorText,
  setErrorText,
  setNewValue,
}: CoordinateEditControlProps): JSX.Element {
  const [coordinateText, setCoordinateText] = useState("");

  return (
    <Stack
      direction="row"
      spacing={1}
      alignContent="center"
      sx={{
        height: "35px",
      }}
    >
      <FaroText
        variant="bodyM"
        alignContent="center"
        sx={{
          fontSize: "12px",
        }}
      >
        {label}
      </FaroText>

      <TextField
        fullWidth
        sx={{ height: "35px" }}
        text={coordinateText}
        error={errorText}
        onTextChanged={(newText) => {
          setCoordinateText(newText);
          const newElevation = Number(newText);
          if (isNaN(newElevation)) {
            setErrorText("Coordinate must be a number");
            setNewValue(undefined);
          } else {
            setErrorText("");
            if (newText !== coordinateText) {
              setNewValue(newElevation);
            }
          }
        }}
      />
    </Stack>
  );
}

type TwoCoordinatesControlProps = {
  /** text of title above two coordinates edit control */
  title: string;

  /** method to send updated reference point coordinates to parent component */
  changeReferencePoint(position?: Vector3Tuple): void;
};

function TwoCoordinatesControl({
  title,
  changeReferencePoint,
}: TwoCoordinatesControlProps): JSX.Element {
  const [errorText, setErrorText] = useState("");
  const [pointCoordinateX, setPointCoordinateX] = useState<
    number | undefined
  >();
  const [pointCoordinateY, setPointCoordinateY] = useState<
    number | undefined
  >();

  useEffect(() => {
    changeReferencePoint(
      pointCoordinateX && pointCoordinateY && !errorText
        ? [pointCoordinateX, 0, pointCoordinateY]
        : undefined,
    );
  }, [changeReferencePoint, errorText, pointCoordinateX, pointCoordinateY]);

  return (
    <Stack spacing={1}>
      <FaroText
        variant="bodyM"
        sx={{
          fontSize: "12px",
          fontWeight: 600,
        }}
      >
        {title}
      </FaroText>

      <CoordinateEditControl
        label="X"
        errorText={errorText}
        setErrorText={setErrorText}
        setNewValue={setPointCoordinateX}
      />
      <CoordinateEditControl
        label="Y"
        errorText={errorText}
        setErrorText={setErrorText}
        setNewValue={setPointCoordinateY}
      />
    </Stack>
  );
}
