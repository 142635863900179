import { TypedEvent } from "@faro-lotv/foundation";
import { TouchEvents, memberWithPrivateData } from "@faro-lotv/lotv";
import { Camera, MOUSE, Matrix4, Plane, Vector3 } from "three";

/** The currently active interaction mode. */
enum InteractionMode {
  /** Translate the object parallel to the camera. */
  translation = "translation",
  /** Rotate the object along the camera direction axis. */
  rotation = "rotation",
}

/** The distance in screen coordinates to register a click on the pin. */
const PIN_DISTANCE_SCREEN = 30;
/** The distance in screen coordinates to perform a rotation instead of translation. */
const ROTATION_DISTANCE_SCREEN = 30;

/**
 * Handler for pin interaction logic.
 *
 * A pin can be placed by clicking on the screen and used for object manipulation:
 *
 * - Translation: Move the object by clicking on the pin and dragging
 * - Rotation: Rotate the object by clicking outside of the pin and orbiting around it
 *
 * All logic and events are calculated in screen space and need to be converted to world space if used in 3D scenes.
 */
export class SinglePinInteraction {
  /**
   * An event that's emitted when the pin should be set.
   * The event contains the position of the pin in world space.
   */
  setPinEvent: TypedEvent<Vector3> = new TypedEvent<Vector3>();
  /** An event that's emitted when the pin should be removed. */
  removePinEvent: TypedEvent<void> = new TypedEvent<void>();

  /**
   * Event fired when the object's transform changed due to the user's manipulation.
   * The event contains a matrix representing the change in transformation.
   *
   * Multiply this matrix with the current object's transform to get the new transform.
   */
  transformEvent: TypedEvent<Matrix4> = new TypedEvent<Matrix4>();

  /**
   * Event fired when the mouse is moved while the pin controls are active
   * The boolean indicates whether the mouse is currently over the pin
   */
  mouseMovedEvent: TypedEvent<boolean> = new TypedEvent<boolean>();

  /** The active camera to use for projections. */
  camera: Camera;
  /** Cached allocation for the direction of the camera. */
  #cameraDirectionWorld: Vector3 = new Vector3();

  /** Whether rotation and translation events are enabled. Camera movement is always enabled. */
  #isManipulationEnabled = true;

  /** Handlers for touch events to react to. */
  #touchEvents: TouchEvents = new TouchEvents();

  /**
   * Cached allocation for the mouse position in screen space.
   * Not guaranteed to be up-to-date, needs to be set first.
   * The z coordinate is expected to be 0.
   */
  #mousePosScreen: Vector3 = new Vector3();
  /**
   * Cached allocation for the mouse position in world space.
   * Needs to be set first.
   */
  #mousePosWorld: Vector3 = new Vector3();

  /** The position of the pin in world space. */
  #pinPosWorld: Vector3 = new Vector3();
  /**
   * The cached position of the pin in screen space.
   * Derived from `this.#pinPosWorld`, needs to be set before being used.
   */
  #pinPosScreen: Vector3 = new Vector3();
  /**
   * The offset between the pin and the object, in world space.
   * Used to keep the offset consistent during translation.
   */
  #pinObjectOffsetWorld: Vector3 = new Vector3();

  /**
   * The plane on which to place the pin such that it stays close to the object.
   * This makes the tool more robust to perspective changes.
   */
  #pinPlaneWorld: Plane = new Plane();

  /** Whether the pin is currently active and visible to the user. */
  #isPinActive: boolean = false;
  /** @returns Whether the pin is currently active and visible to the user. */
  get isPinActive(): boolean {
    return this.#isPinActive;
  }
  /**
   * @param value Whether the pin should be active and visible to the user.
   */
  set isPinActive(value: boolean) {
    if (value === this.#isPinActive) return;

    if (value) {
      this.#setPin(this.#pinPosWorld);
    } else {
      this.#removePin();
    }
  }

  /**
   * The current mode of interaction.
   * If undefined, manipulation is disabled.
   */
  #interactionMode?: InteractionMode = undefined;

  /**
   * The reference position where the manipulation started in world space.
   * Used to calculate the rotation.
   */
  #referencePosWorld: Vector3 = new Vector3();

  /** Last positional reference to calculate the relative transform change. */
  #lastPos: Vector3 = new Vector3();
  /** The last reference used to calculate the rotation. */
  #lastRotReference: Vector3 = new Vector3();

  /**
   * The cached reference position where the manipulation started in screen space.
   * Derived from `this.#referencePosWorld`, needs to be set before being used.
   */
  #referencePosScreen: Vector3 = new Vector3();

  /**
   * Accessor for the current center of the manipulated object.
   *
   * The object returned by this function may come from the store, so it must be copied/cloned before being used.
   */
  getObjectCenter: () => Vector3;
  /** The center position of the manipulated object. */
  #objectCenterWorld: Vector3 = new Vector3();

  /** Cached transformation matrix to send with the events. */
  #transformMatrix4: Matrix4 = new Matrix4();

  /**
   * @param camera The active camera to use for projections.
   * @param getObjectTransform Accessor for the current transform of the manipulated object.
   * @param getObjectCenter Accessor for the current center of the manipulated object.
   */
  constructor(camera: Camera, getObjectCenter: () => Vector3) {
    this.camera = camera;
    this.getObjectCenter = getObjectCenter;
  }

  /** @returns The DOM element to use for screen space calculations. */
  get domElement(): HTMLElement {
    return this.#touchEvents.element ?? document.body;
  }

  /** @returns Whether the user manipulation (translation/rotation) is possible. */
  get isManipulationEnabled(): boolean {
    return this.#isManipulationEnabled;
  }

  /**
   * @param value enable or disable the user manipulation with the pin.
   */
  set isManipulationEnabled(value: boolean) {
    if (value === this.#isManipulationEnabled) return;
    this.#isManipulationEnabled = value;

    // Remember pin position from last time
    if (value && this.isPinActive) {
      this.setPinEvent.emit(this.#pinPosWorld);
    } else {
      this.removePinEvent.emit();
    }
  }

  /**
   * @param pinPosWorld The new position of the pin in world coordinates.
   */
  #setPin(pinPosWorld: Vector3): void {
    this.#isPinActive = true;
    this.#pinPosWorld.copy(pinPosWorld);
    this.setPinEvent.emit(pinPosWorld);
  }

  /** Remove the current pin and reset the interaction mode. */
  #removePin(): void {
    this.#interactionMode = undefined;
    this.#isPinActive = false;
    this.removePinEvent.emit();
  }

  /**
   * @param event The single click mouse event to react to.
   */
  #onMouseSingleClicked(event: MouseEvent): void {
    if (!this.isManipulationEnabled) return;

    eventToScreenVec(this.domElement, event, this.#mousePosScreen);

    // Setting a new pin
    if (event.button === MOUSE.LEFT && !this.isPinActive) {
      // Project the click position into world space
      this.#mousePosWorld = screenVecToWorldVec(
        this.#mousePosScreen,
        this.#mousePosWorld,
        this.camera,
        this.domElement.clientWidth,
        this.domElement.clientHeight,
      );
      // Until this point the position in camera direction is arbitrary
      // Instead, it should be close to the object center to make the tool robust to perspective changes
      this.camera.getWorldDirection(this.#cameraDirectionWorld);
      this.#objectCenterWorld.copy(this.getObjectCenter());
      this.#pinPlaneWorld.setFromNormalAndCoplanarPoint(
        this.#cameraDirectionWorld,
        this.#objectCenterWorld,
      );
      this.#pinPlaneWorld.projectPoint(this.#mousePosWorld, this.#pinPosWorld);
      this.#setPin(this.#pinPosWorld);
      return;
    }

    // Removing an old pin
    if (event.button === MOUSE.RIGHT && this.isPinActive) {
      this.#pinPosScreen = worldVecToScreenVec(
        this.#pinPosWorld,
        this.#pinPosScreen,
        this.camera,
        this.domElement.clientWidth,
        this.domElement.clientHeight,
      );
      const distanceScreen = this.#pinPosScreen.distanceTo(
        this.#mousePosScreen,
      );

      if (distanceScreen <= PIN_DISTANCE_SCREEN) {
        this.#removePin();
      }
    }
  }

  /**
   * @param event The press mouse event to react to.
   */
  #onMousePressed(event: MouseEvent): void {
    if (
      !this.isManipulationEnabled ||
      event.button !== MOUSE.LEFT ||
      !this.isPinActive
    ) {
      return;
    }

    // Remember where exactly the user clicked to calculate translation/rotation
    eventToScreenVec(this.domElement, event, this.#referencePosScreen);
    this.#referencePosWorld = screenVecToWorldVec(
      this.#referencePosScreen,
      this.#referencePosWorld,
      this.camera,
      this.domElement.clientWidth,
      this.domElement.clientHeight,
    );
    this.#lastPos.set(0, 0, 0);
    this.#lastRotReference.copy(this.#referencePosWorld);

    // Remember the offset between the pin and the mouse
    this.#pinObjectOffsetWorld
      .copy(this.#referencePosWorld)
      .sub(this.#pinPosWorld);

    // Determine whether to use translation or rotation
    this.#pinPosScreen = worldVecToScreenVec(
      this.#pinPosWorld,
      this.#pinPosScreen,
      this.camera,
      this.domElement.clientWidth,
      this.domElement.clientHeight,
    );
    const distanceScreen = this.#referencePosScreen.distanceTo(
      this.#pinPosScreen,
    );

    if (distanceScreen < ROTATION_DISTANCE_SCREEN) {
      this.#interactionMode = InteractionMode.translation;
    } else {
      this.#interactionMode = InteractionMode.rotation;
    }
  }

  /**
   * @param event The movement mouse event to react to.
   */
  #onMouseMoved(event: MouseEvent): void {
    eventToScreenVec(this.domElement, event, this.#mousePosScreen);
    this.#mousePosWorld = screenVecToWorldVec(
      this.#mousePosScreen,
      this.#mousePosWorld,
      this.camera,
      this.domElement.clientWidth,
      this.domElement.clientHeight,
    );

    if (this.isManipulationEnabled && this.isPinActive) {
      switch (this.#interactionMode) {
        case InteractionMode.translation:
          this.#onTranslation();
          break;
        case InteractionMode.rotation:
          this.#onRotation();
          break;
      }
    }

    this.#pinPosScreen = worldVecToScreenVec(
      this.#pinPosWorld,
      this.#pinPosScreen,
      this.camera,
      this.domElement.clientWidth,
      this.domElement.clientHeight,
    );
    const distanceScreen = this.#mousePosScreen.distanceTo(this.#pinPosScreen);

    this.mouseMovedEvent.emit(distanceScreen < ROTATION_DISTANCE_SCREEN);
  }

  #onTranslation(): void {
    // Move the pin together with the object
    this.#pinPosWorld.copy(this.#mousePosWorld).sub(this.#pinObjectOffsetWorld);
    this.#setPin(this.#pinPosWorld);
    // Add translation to object
    const objectOffset = this.#mousePosWorld
      .sub(this.#referencePosWorld)
      .sub(this.#lastPos);

    this.#lastPos.add(objectOffset);

    this.#transformMatrix4.makeTranslation(objectOffset);
    this.transformEvent.emit(this.#transformMatrix4);
  }

  #onRotation(): void {
    // Determine the plane on which the rotation should take place
    this.camera.getWorldDirection(this.#cameraDirectionWorld);
    this.#pinPlaneWorld.setFromNormalAndCoplanarPoint(
      this.#cameraDirectionWorld,
      this.#objectCenterWorld,
    );

    // Add rotation to the object
    rotateAround(
      this.#pinPosWorld,
      this.#lastRotReference,
      this.#mousePosWorld,
      this.#pinPlaneWorld,
      this.#transformMatrix4,
    );

    this.#lastRotReference.copy(this.#mousePosWorld);

    this.transformEvent.emit(this.#transformMatrix4);
  }

  /**
   * @param event The click release mouse event to react to.
   */
  #onMouseReleased(event: MouseEvent): void {
    if (
      !this.isManipulationEnabled ||
      event.button !== MOUSE.LEFT ||
      !this.#interactionMode
    ) {
      return;
    }

    this.#interactionMode = undefined;
  }

  /**
   * Center the pin on the manipulated object.
   *
   * @returns the new position of the pin in world space.
   */
  centerPin(): Vector3 {
    this.#objectCenterWorld.copy(this.getObjectCenter());
    this.#setPin(this.#objectCenterWorld);
    return this.#objectCenterWorld;
  }

  /**
   * @param element The HTML element to track interaction events of.
   * @returns This instance for chaining.
   */
  attach(element: HTMLElement): this {
    this.#touchEvents.attach(element);

    this.#touchEvents.mouseSingleClicked.on(
      this.#onMouseSingleClicked.bind(this),
    );
    this.#touchEvents.mousePressed.on(this.#onMousePressed.bind(this));
    this.#touchEvents.mouseMoved.on(this.#onMouseMoved.bind(this));
    this.#touchEvents.mouseReleased.on(this.#onMouseReleased.bind(this));

    return this;
  }

  /**
   * Detach the interaction events from the current element.
   *
   * @returns This instance for chaining.
   */
  detach(): this {
    this.#touchEvents.detach();

    this.#touchEvents.mouseSingleClicked.off(this.#onMouseSingleClicked);
    this.#touchEvents.mousePressed.off(this.#onMousePressed);
    this.#touchEvents.mouseMoved.off(this.#onMouseMoved);
    this.#touchEvents.mouseReleased.off(this.#onMouseReleased);

    return this;
  }
}

/**
 * Create a rotation matrix around a point in 3D space.
 * The rotation will be around the normal of the provided plane.
 *
 * @param anchorPosition The 3D position the around which we want to rotate
 * @param startPoint The starting 3D position of the interaction
 * @param endPoint The ending 3D position of the interaction
 * @param plane The plane on which the direction should occur
 * @param target The matrix to write the result to
 * @returns The mutated `target` matrix, containing the result
 */
export const rotateAround = memberWithPrivateData(() => {
  const TEMP_VEC3_1 = new Vector3();
  const TEMP_VEC3_2 = new Vector3();
  const TEMP_VEC3_3 = new Vector3();
  const TEMP_MAT4_1 = new Matrix4();

  return (
    anchorPosition: Vector3,
    startPoint: Vector3,
    endPoint: Vector3,
    rotationPlane: Plane,
    target: Matrix4,
  ): Matrix4 => {
    const projectedAnchor = rotationPlane.projectPoint(
      anchorPosition,
      TEMP_VEC3_3,
    );
    const initialDirection = rotationPlane
      .projectPoint(startPoint, TEMP_VEC3_1)
      .sub(projectedAnchor)
      .normalize();
    const currentDirection = rotationPlane
      .projectPoint(endPoint, TEMP_VEC3_2)
      .sub(projectedAnchor)
      .normalize();

    // Determine the direction of and magnitude of the rotation
    const cross = TEMP_VEC3_3.crossVectors(
      initialDirection,
      currentDirection,
    ).normalize();
    const angleSign = cross.dot(rotationPlane.normal) > 0 ? 1 : -1;
    const angle = angleSign * initialDirection.angleTo(currentDirection);

    // Compute the final matrix by first translating the object at the origin,
    // rotating it around Y and bringing it back to its position
    return target
      .makeTranslation(anchorPosition.x, anchorPosition.y, anchorPosition.z)
      .multiply(TEMP_MAT4_1.makeRotationAxis(rotationPlane.normal, angle))
      .multiply(
        TEMP_MAT4_1.makeTranslation(
          -anchorPosition.x,
          -anchorPosition.y,
          -anchorPosition.z,
        ),
      );
  };
});

/**
 * Get the position of the mouse event in screen coordinates (pixels).
 * The position will be relative to the provided `domElement`.
 *
 * @param domElement The element to calculate the position relative to.
 * @param event The mouse event to get the position from.
 * @param screenVec The vector to write the screen coordinates to.
 * @returns The `screenVec`, mutated with the result.
 */
export function eventToScreenVec(
  domElement: HTMLElement,
  event: MouseEvent,
  screenVec: Vector3,
): Vector3 {
  // The event target might be different from `domElement`, so event.offsetX/Y cannot be used
  const boundingRect = domElement.getBoundingClientRect();
  const x = event.clientX - boundingRect.left;
  const y = event.clientY - boundingRect.top;
  return screenVec.set(x, y, 0);
}

/**
 * Convert a vector in world coordinates to a vector in screen coordinates (pixels).
 *
 * @param worldVec The vector in world coordinates.
 * @param screenVec The vector in screen coordinates to write the result to.
 * @param camera The camera to project the world coordinates to screen coordinates.
 * @param screenWidth The width of the screen in pixels, e.g. `domElement.clientWidth`.
 * @param screenHeight The height of the screen in pixels, e.g. `domElement.clientHeight`.
 * @returns The mutated `screenVec`. The z coordinate is set to 0.
 */
export function worldVecToScreenVec(
  worldVec: Vector3,
  screenVec: Vector3,
  camera: Camera,
  screenWidth: number,
  screenHeight: number,
): Vector3 {
  // World -> Camera
  screenVec.copy(worldVec).project(camera);
  // Camera -> Screen
  return cameraVecToScreenVec(screenVec, screenVec, screenWidth, screenHeight);
}

/**
 * Convert a vector from camera coordinates (NDC) to screen coordinates (pixels).
 *
 * @param cameraVec The camera vector in normalized device coordinates (NDC).
 * @param screenVec The screen vector in pixels to write the result to.
 * @param screenWidth The width of the screen in pixels, e.g. `domElement.clientWidth`.
 * @param screenHeight The height of the screen in pixels, e.g. `domElement.clientHeight`.
 * @returns The mutated `screenVec`, containing the result.
 *  The z coordinate is set to 0.
 */
export function cameraVecToScreenVec(
  cameraVec: Vector3,
  screenVec: Vector3,
  screenWidth: number,
  screenHeight: number,
): Vector3 {
  const halfWidth = screenWidth / 2;
  const halfHeight = screenHeight / 2;

  return screenVec.set(
    cameraVec.x * halfWidth + halfWidth,
    -(cameraVec.y * halfHeight) + halfHeight,
    0,
  );
}

/**
 * Convert a vector in screen coordinates (pixels) to a vector in world coordinates.
 *
 * @param screenVec The vector in screen coordinates. The z coordinate is expected to be 0.
 * @param worldVec The vector in world coordinates to write the result to.
 * @param camera The camera to unproject the screen coordinates to world coordinates.
 * @param screenWidth The width of the screen in pixels, e.g. `domElement.clientWidth`.
 * @param screenHeight The height of the screen in pixels, e.g. `domElement.clientHeight`.
 * @returns The mutated `worldVec`.
 */
export function screenVecToWorldVec(
  screenVec: Vector3,
  worldVec: Vector3,
  camera: Camera,
  screenWidth: number,
  screenHeight: number,
): Vector3 {
  // Screen -> Camera
  screenVecToCameraVec(screenVec, worldVec, screenWidth, screenHeight);
  // Camera -> World
  return worldVec.unproject(camera);
}

/**
 * Convert a vector in screen coordinates (pixels) to a vector in camera coordinates (NDC).
 *
 * @param screenVec The vector in screen coordinates. The z coordinate is expected to be 0.
 * @param cameraVec The vector in camera coordinates to write the result to.
 * @param screenWidth The width of the screen in pixels, e.g. `domElement.clientWidth`.
 * @param screenHeight The height of the screen in pixels, e.g. `domElement.clientHeight`.
 * @returns The mutated `cameraVec`, containing the result.
 */
export function screenVecToCameraVec(
  screenVec: Vector3,
  cameraVec: Vector3,
  screenWidth: number,
  screenHeight: number,
): Vector3 {
  return cameraVec.set(
    (screenVec.x / screenWidth) * 2 - 1,
    -(screenVec.y / screenHeight) * 2 + 1,
    0,
  );
}
