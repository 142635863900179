import { ElementIconType } from "@/components/ui/icons";
import { RootState } from "@/store/store";
import { GUID, assert, generateGUID } from "@faro-lotv/foundation";
import {
  IElement,
  IElementGenericPointCloud,
  isIElementGenericPointCloud,
} from "@faro-lotv/ielement-types";
import {
  PointCloudType,
  selectChildDepthFirst,
  selectIElement,
  selectPointCloudType,
  selectRootId,
} from "@faro-lotv/project-source";
import {
  ProjectApi,
  createMutationAddEmptyLayerSection,
  createMutationGenerateFloorplansForPointCloud,
  isElementAllowedForFloorplanGeneration,
} from "@faro-lotv/service-wires";
import { ContextMenuAction, ContextMenuActionType } from "../action-types";

/**
 * Trigger the generation of floorplan(s) for the selected model
 */
export const GENERATE_FLOORPLAN: ContextMenuAction = {
  type: ContextMenuActionType.generateFloorplan,
  label: "Generate floorplan",
  icon: ElementIconType.SheetNewIcon,
  handler: async ({
    elementID,
    openToast,
    state,
    apiClients: { projectApiClient },
    errorHandlers: { handleErrorWithToast },
    createDialog,
  }) => {
    const hasConfirmed = await createDialog({
      title: "Floorplan generation",
      content:
        "Generating a floorplan incurs a backend cost. Please confirm if you want to proceed.",
      confirmText: "Start",
    });
    if (!hasConfirmed) return;

    const rootId = selectRootId(state);
    assert(rootId, "Can only create empty layer with root ID.");

    const element = selectIElement(elementID)(state);

    const datasetSection = selectChildDepthFirst(
      element,
      isElementAllowedForFloorplanGeneration,
    )(state);
    assert(datasetSection, "Can't find suitable dataset section.");

    /** Pointcloud to use to generate the floorplan */
    const pcElement = selectPointCloudForFloorplan(element)(state);
    assert(pcElement, "Unable to find suitable point cloud.");

    const layerId = generateGUID();

    try {
      await startFloorplanGeneration({
        projectApiClient,
        layerId,
        rootId,
        sectionId: datasetSection.id,
        pointcloudElement: pcElement,
      });

      openToast({
        title:
          "Generation of floorplan(s) have been started. You will receive the files via email once it's finished.",
        variant: "success",
      });
    } catch (error) {
      handleErrorWithToast({ title: "Unable to generate floorplan", error });
    }
  },
};

type StartFloorplanGenerationArgs = {
  /** Client to use */
  projectApiClient: ProjectApi;
  /** ID of the layer section to create the point cloud in */
  layerId: GUID;
  /** ID of the root element */
  rootId: GUID;
  /** ID of the section to append the empty layer to */
  sectionId: GUID;
  /** Point cloud to generate the floorplan(s) */
  pointcloudElement: IElementGenericPointCloud;
};

async function startFloorplanGeneration({
  projectApiClient,
  layerId,
  rootId,
  sectionId,
  pointcloudElement,
}: StartFloorplanGenerationArgs): Promise<void> {
  const addEmptyLayerMutation = createMutationAddEmptyLayerSection({
    layerId,
    rootId,
    sectionId,
    name: `Empty layer for section ${sectionId}`,
  });
  const floorplanGenerationMutation =
    createMutationGenerateFloorplansForPointCloud({
      rootId,
      name: `Floorplan generation element for layer ${layerId}`,
      uri: pointcloudElement.uri,
      layerId,
      md5Hash: pointcloudElement.md5Hash,
      fileName: pointcloudElement.fileName,
      fileSize: pointcloudElement.fileSize,
    });

  await projectApiClient.applyMutations([
    addEmptyLayerMutation,
    floorplanGenerationMutation,
  ]);
}

/**
 * Select the point cloud to use for the floorplan generation, giving priority to the project point cloud.
 *
 * @param referenceElement The element to use as reference to find the children pointcloud
 * @returns The point cloud to use for the floorplan generation
 */
function selectPointCloudForFloorplan(referenceElement: IElement | undefined) {
  return (state: RootState): IElementGenericPointCloud | undefined => {
    const projectPointCloud = selectChildDepthFirst(
      referenceElement,
      (el: IElement): el is IElementGenericPointCloud =>
        isIElementGenericPointCloud(el) &&
        selectPointCloudType(el)(state) === PointCloudType.project,
    )(state);

    const pcElement =
      projectPointCloud ??
      selectChildDepthFirst(
        referenceElement,
        isIElementGenericPointCloud,
      )(state);

    return pcElement;
  };
}
