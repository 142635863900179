import { ModalSpinner } from "@/components/ui/modal-spinner";
import { changeMode } from "@/store/mode-slice";
import { selectElementToAlignWithControlPointsAlignment } from "@/store/modes/control-points-alignment-mode-selectors";
import { resetControlPointsAlignment } from "@/store/modes/control-points-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectIElement, ViewDiv } from "@faro-lotv/app-component-toolbox";
import { neutral, useToast } from "@faro-lotv/flat-ui";
import { Stack } from "@mui/material";
import { useCallback, useState } from "react";
import {
  AlignmentElementLabel,
  LabelType,
} from "../alignment-modes-commons/alignment-element-label";
import { useOverlayElements, useOverlayRef } from "../overlay-elements-context";
import { ControlPointsAlignmentProgressBar } from "./control-points-alignment-progress-bar";
import { ControlPointsAlignmentSetPointsPanel } from "./control-points-alignment-set-points-panel";

/** @returns The overlay for control-points alignment mode */
export function ControlPointsAlignmentModeOverlay(): JSX.Element {
  const [showSpinner, setShowSpinner] = useState(false);
  const { openToast } = useToast();
  const dispatch = useAppDispatch();

  const elementToAlignId = useAppSelector(
    selectElementToAlignWithControlPointsAlignment,
  );
  if (!elementToAlignId) {
    throw new Error("Element to align not assigned");
  }

  const elementToAlign = useAppSelector(selectIElement(elementToAlignId));

  const applyAreaMutation = useCallback(() => {
    setShowSpinner(true);

    // TODO: computation and applying mutation will be done in https://faro01.atlassian.net/browse/CADBIM-1187
    // const incrementalTransform = selectControlPointsAlignmentTransform(store.getState()) ?? IDENTITY;

    // at the end of alignment cycle reset temporary data to prevent reusing it in the next session of alignment
    dispatch(resetControlPointsAlignment());

    setShowSpinner(false);
    openToast({
      title: "Alignment completed",
      variant: "success",
    });

    // after alignment force switch to overview mode as most convenient to validate alignment result in main 3D view
    dispatch(changeMode("overview"));
  }, [dispatch, openToast]);

  const { setSingleScreen } = useOverlayElements();
  const singleScreenRef = useOverlayRef(setSingleScreen);

  return (
    <>
      <ModalSpinner
        sx={{ color: neutral[0], zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showSpinner}
      />
      <Stack
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      >
        <ControlPointsAlignmentProgressBar apply={applyAreaMutation} />

        <Stack direction="row" sx={{ height: "100%" }}>
          <ViewDiv
            eventDivRef={singleScreenRef}
            sx={{
              height: "100%",
              width: "100%",
              borderRight: 3,
              borderRightColor: neutral[200],
            }}
          >
            {elementToAlign && (
              <AlignmentElementLabel
                element={elementToAlign}
                labelType={LabelType.alignedElement}
                sx={{
                  top: "10px",
                  left: "30px",
                }}
              />
            )}
          </ViewDiv>
          <ControlPointsAlignmentSetPointsPanel
            layerOrAreaId={elementToAlignId}
          />
        </Stack>
      </Stack>
    </>
  );
}
