import { useDocumentTitle } from "@/hooks/use-document-title";
import { EnsureProjectAccess } from "@/permissions/ensure-project-access";
import { runtimeConfig } from "@/runtime-config";
import { useAppSelector } from "@/store/store-hooks";
import { selectCurrentUser } from "@/store/user-selectors";
import { appId } from "@/utils/appid";
import { ProgressApiTracker } from "@/utils/progress-api-tracker";
import { LoadingScreen } from "@faro-lotv/flat-ui";
import {
  useAnalyticsInitialization,
  useTranslate,
} from "@faro-lotv/foreign-observers";
import { GUID, assert } from "@faro-lotv/foundation";
import { selectProjectName } from "@faro-lotv/project-source";
import { ApiClientContextProvider } from "@faro-lotv/service-wires";
import { useParams, useSearchParams } from "react-router-dom";
import { DataPreparationUi } from "./data-preparation-ui";
import { useLoadRevision } from "./loading/use-load-revision";
import { useLoadRevisionEdges } from "./loading/use-load-revision-edges";
import { useLoadRevisionEntities } from "./loading/use-load-revision-entities";

/**
 * @returns Entry point of the data preparation page (aka the staging area).
 * It allows the user to register and publish new scans.
 */
export function DataPreparationPage(): JSX.Element | null {
  const { projectId } = useParams();
  assert(projectId, "No project ID provided");

  const currentUser = useAppSelector(selectCurrentUser);

  const [searchParams] = useSearchParams();
  const revisionId = searchParams.get("revisionId");
  assert(revisionId, "The revisionId parameter must be provided");

  const translate = useTranslate();

  const pageTitle = translate("Data Preparation");
  const projectName = useAppSelector(selectProjectName);
  const title = projectName ? `${pageTitle} - ${projectName}` : pageTitle;
  useDocumentTitle(title);

  useAnalyticsInitialization(
    runtimeConfig.analytics.amplitudeApiKey,
    currentUser?.email,
  );

  return (
    <ApiClientContextProvider
      projectId={projectId}
      userId={currentUser?.id}
      clientId={appId()}
      apiBaseUrls={runtimeConfig.backendEndpoints}
    >
      <EnsureProjectAccess projectId={projectId}>
        <DataPreparationLoading revisionId={revisionId} />
        <ProgressApiTracker />
      </EnsureProjectAccess>
    </ApiClientContextProvider>
  );
}

type DataPreparationLoadingProps = {
  /** The ID of the revision containing the registration results. */
  revisionId: GUID;
};

/** @returns Wrapper component which ensures that all needed data is loaded and validated. */
function DataPreparationLoading({
  revisionId,
}: DataPreparationLoadingProps): JSX.Element {
  // Load all data
  const revision = useLoadRevision(revisionId);
  const entities = useLoadRevisionEntities(revisionId);
  useLoadRevisionEdges(revisionId);

  if (!revision || !entities) return <LoadingScreen />;

  return <DataPreparationUi revision={revision} />;
}
