/**
 * A module to expose values that depends by environment variables
 * set during the build process.
 * Vite expose those variables trough the import.meta.env object
 * but Jest can't parse that object.
 *
 * Wrapping them in a module allow Jest to mock the entire module for the entire test run
 *
 * If you change this file remember to update the "src/tests/setup-tests.ts" file too for the mocks
 */

/** Current app version (semver, test string, or commit id) */
export const APP_VERSION = import.meta.env.VITE_APP_VERSION;

/** True if we're running the production bundle  */
export const IS_PROD = !!import.meta.env.PROD;

/** Custom environment/region to execute the app from */
export const CUSTOM_ENV = import.meta.env.VITE_CUSTOM_ENV;

/** True if the app should auto enable preview features */
export const PREVIEW_FEATURES = import.meta.env.VITE_PREVIEW_FEATURES
  ? import.meta.env.VITE_PREVIEW_FEATURES === "true"
  : undefined;

/** True to force the Localize integration to save new phrases in the LocalizeJS database */
export const FORCE_LOCALIZE_SAVE_STRINGS =
  import.meta.env.VITE_FORCE_LOCALIZE_SAVE_STRINGS === "true";
