import { Mode } from "@/modes/mode";
import { changeMode } from "@/store/mode-slice";
import { ControlPointsAlignmentModeOverlay } from "./control-points-alignment-mode-overlay";
import { ControlPointsAlignmentModeScene } from "./control-points-alignment-mode-scene";

export const controlPointsAlignmentMode: Mode = {
  name: "controlPointsAlignment",
  ModeScene: ControlPointsAlignmentModeScene,
  ModeOverlay: ControlPointsAlignmentModeOverlay,
  hasCustomCanvasStyle: true,
  exclusive: {
    title: "Align using control points",
    onBack({ dispatch }) {
      dispatch(changeMode("start"));

      return Promise.resolve();
    },
  },
};
