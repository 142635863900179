import { SheetObject } from "@/object-cache";
import { useAppSelector } from "@/store/store-hooks";
import {
  FloorPlanRendererBase,
  selectIElementWorldTransform,
  useAssignClippingPlanes,
} from "@faro-lotv/app-component-toolbox";
import {
  IElementGenericImgSheet,
  IElementImgSheetTiled,
} from "@faro-lotv/ielement-types";
import { FloorPlanBackgroundTransparency, LodFloorPlan } from "@faro-lotv/lotv";
import { EventHandlers } from "@react-three/fiber/dist/declarations/src/core/events";
import { useEffect } from "react";
import { Mesh, Plane, Side } from "three";

export type SimpleSheetRendererProps = EventHandlers & {
  /** The mesh with the floor plan as a texture */
  mesh: Mesh;
  /** The IElement descriptor for this floor plan */
  iElement: IElementGenericImgSheet;
  /** Optional clipping planes */
  clippingPlanes?: Plane[];
};

/**
 * @returns Renderer for an not Lod ImgSheet
 */
export function SimpleSheetRenderer({
  mesh,
  iElement,
  clippingPlanes,
  ...events
}: SimpleSheetRendererProps): JSX.Element {
  useAssignClippingPlanes(mesh, clippingPlanes);

  const DEFAULT_MAP_SIZE = 100;
  const mapWidth = iElement.size?.x ?? DEFAULT_MAP_SIZE;
  const mapHeight = iElement.size?.z ?? DEFAULT_MAP_SIZE;

  const globalPose = useAppSelector(selectIElementWorldTransform(iElement.id));

  return (
    <group {...globalPose} {...events}>
      {/* Translating the mesh so that the position corresponds to the sheet center. The translation is in the XY plane
          because the rotation to the Y-up system is taken care of by 'globalPose' */}
      <primitive
        object={mesh}
        position={[mapWidth / 2, mapHeight / 2, 0]}
        name={iElement.id}
      />
    </group>
  );
}

export type LodSheetRendererProps = EventHandlers & {
  /** The LodFloorPlan to render for this floor plan */
  lod: LodFloorPlan;

  /** The IElement that describe this floor plan */
  iElement: IElementImgSheetTiled;

  /** Optional clipping planes */
  clippingPlanes?: Plane[];
};

/**
 * @returns the floor plan in the correct position
 */
export function LodSheetRenderer({
  lod,
  iElement,
  clippingPlanes,
  ...events
}: LodSheetRendererProps): JSX.Element {
  useAssignClippingPlanes(lod, clippingPlanes);

  const globalPosition = useAppSelector(
    selectIElementWorldTransform(iElement.id),
  );

  return (
    <group
      position={globalPosition.position}
      quaternion={globalPosition.quaternion}
      scale={globalPosition.scale}
      {...events}
    >
      <FloorPlanRendererBase floorPlan={lod} minPixelSize={100} />
    </group>
  );
}

export type SheetRendererProps = EventHandlers & {
  sheet: SheetObject;

  /** Override the default transparency. */
  transparent?: boolean;

  /** Override the default side culling. */
  side?: Side;

  /** Optional clipping planes */
  clippingPlanes?: Plane[];

  /** Override the default background transparency. */
  backgroundTransparent?: FloorPlanBackgroundTransparency;
};

/**
 * @returns the floor plan in the correct position
 */
export function SheetRenderer({
  sheet,
  side,
  transparent,
  clippingPlanes,
  backgroundTransparent,
  ...events
}: SheetRendererProps): JSX.Element {
  useEffect(() => {
    if (transparent === undefined) return;
    const origTransparent = sheet.material.transparent;
    sheet.material.transparent = transparent;

    return () => {
      sheet.material.transparent = origTransparent;
    };
  }, [sheet.material, transparent]);

  useEffect(() => {
    if (!side) return;
    const origSide = sheet.material.side;
    sheet.material.side = side;

    return () => {
      // Apply original side
      sheet.material.side = origSide;
    };
  }, [sheet.material, side]);

  useEffect(() => {
    if (backgroundTransparent === undefined) return;
    const origBackgroundTransparent = sheet.material.backgroundTransparent;
    sheet.material.backgroundTransparent = backgroundTransparent;

    return () => {
      // Apply original backgroundTransparent
      sheet.material.backgroundTransparent = origBackgroundTransparent;
    };
  }, [backgroundTransparent, sheet.material]);

  if (sheet instanceof LodFloorPlan) {
    return (
      <LodSheetRenderer
        lod={sheet}
        iElement={sheet.iElement}
        clippingPlanes={clippingPlanes}
        {...events}
      />
    );
  }
  return (
    <SimpleSheetRenderer
      mesh={sheet}
      iElement={sheet.iElement}
      clippingPlanes={clippingPlanes}
      {...events}
    />
  );
}
