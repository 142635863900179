import { AlignmentTransform } from "@/alignment-tool/store/alignment-slice";
import { TwoPointsPairsAlignmentAnchorPositions } from "@/alignment-tool/utils/compute-split-screen-alignment";
import { GUID } from "@faro-lotv/foundation";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Vector3Tuple } from "three";

/** State managing data exchange during control points alignment session */
export type ControlPointsAlignmentToolState = {
  /** id of area or layer to align */
  layerOrAreaId?: GUID;

  /** Elevation of layer/area plane relatively origin in W.C.S., in meters  */
  sheetElevation?: number;

  /**
   * Incremental transform of that will align the layer/area in its current world. Translations are in meters.
   * At the end of each alignment cycle, it must be reset to undefined to prevent reusing it in next session of alignment
   */
  incrementalTransform?: AlignmentTransform;

  /** anchor positions defined in split screen alignment */
  alignmentAnchorPositions: TwoPointsPairsAlignmentAnchorPositions;
};

const initialState: ControlPointsAlignmentToolState = {
  alignmentAnchorPositions: {},
};

const controlPointsAlignmentModeSlice = createSlice({
  initialState,
  name: "controlPointsAlignmentMode",
  reducers: {
    setElementToAlignByControlPointsAlignment(
      state,
      action: PayloadAction<GUID>,
    ) {
      state.layerOrAreaId = action.payload;
    },

    setAreaOrAreaTransform(
      state,
      action: PayloadAction<AlignmentTransform | undefined>,
    ) {
      state.incrementalTransform = action.payload;
    },

    setControlPointsSheetElevation(
      state,
      action: PayloadAction<number | undefined>,
    ) {
      state.sheetElevation = action.payload;
    },

    resetControlPointsAlignment(state) {
      state.sheetElevation = undefined;
      state.incrementalTransform = undefined;
      state.layerOrAreaId = undefined;
      state.alignmentAnchorPositions = {};
    },

    setControlPointsMovingElementAnchor1(
      state,
      action: PayloadAction<Vector3Tuple | undefined>,
    ) {
      state.alignmentAnchorPositions.movingElementAnchor1 = action.payload;
    },

    setControlPointsMovingElementAnchor2(
      state,
      action: PayloadAction<Vector3Tuple | undefined>,
    ) {
      state.alignmentAnchorPositions.movingElementAnchor2 = action.payload;
    },

    setControlPointsReferencePoint1(
      state,
      action: PayloadAction<Vector3Tuple | undefined>,
    ) {
      state.alignmentAnchorPositions.referenceElementAnchor1 = action.payload;
    },

    setControlPointsReferencePoint2(
      state,
      action: PayloadAction<Vector3Tuple | undefined>,
    ) {
      state.alignmentAnchorPositions.referenceElementAnchor2 = action.payload;
    },
  },
});

export const {
  setElementToAlignByControlPointsAlignment,
  setAreaOrAreaTransform,
  setControlPointsSheetElevation,
  resetControlPointsAlignment,
  setControlPointsMovingElementAnchor1,
  setControlPointsMovingElementAnchor2,
  setControlPointsReferencePoint1,
  setControlPointsReferencePoint2,
} = controlPointsAlignmentModeSlice.actions;

export const controlPointsAlignmentModeReducer =
  controlPointsAlignmentModeSlice.reducer;
