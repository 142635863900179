import {
  EventType,
  SelectLocationProperties,
} from "@/analytics/analytics-events";
import { AnalysesRenderer } from "@/components/r3f/renderers/analyses/analyses-renderer";
import { AnnotationsRenderer } from "@/components/r3f/renderers/annotations/annotations-renderer";
import { CadRenderer } from "@/components/r3f/renderers/cad-renderer";
import { MeasurementsRenderer } from "@/components/r3f/renderers/measurements/measurements-renderer";
import { WalkPathsRenderer } from "@/components/r3f/renderers/odometry-paths/walk-paths-renderer";
import { PointCloudRenderer } from "@/components/r3f/renderers/pointcloud-renderer";
import { SheetRenderer } from "@/components/r3f/renderers/sheet-renderer";
import { WaypointLabelRender } from "@/components/r3f/renderers/waypoint-label-render";
import { useSceneContextMenuEventHandlers } from "@/hooks/use-scene-context-menu-event-handlers";
import { useWalkPlaceholderPositions } from "@/hooks/use-walk-placeholder-positions";
import { CadModelObject, PointCloudObject, SheetObject } from "@/object-cache";
import { Measurement } from "@/store/measurement-tool-slice";
import {
  selectOverviewIsCadSelected,
  selectOverviewIsPointCloudSelected,
} from "@/store/modes/overview-mode-slice";
import { PointCloudAnalysis } from "@/store/point-cloud-analysis-tool-slice";
import { useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import {
  selectObjectVisibility,
  selectShouldShowWaypointsOnFloors,
} from "@/store/view-options/view-options-selectors";
import { ViewObjectTypes } from "@/store/view-options/view-options-slice";
import {
  OverviewWaypointsRenderer,
  UniformLights,
} from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import {
  GUID,
  IElementGenericAnnotation,
  IElementGenericImgSheet,
  IElementImg360,
  IElementSection,
} from "@faro-lotv/ielement-types";
import { FloorPlanBackgroundTransparency } from "@faro-lotv/lotv";
import { ThreeEvent } from "@react-three/fiber";
import { DomEvent } from "@react-three/fiber/dist/declarations/src/core/events";
import { Plane } from "three";
import {
  useOffsetPlaceholders,
  useVisiblePlaceholders,
  useWaypoints,
} from "../../hooks/use-placeholders";
import { OverviewRenderingPipeline } from "./overview-rendering-pipeline";

type OverviewModeSceneBaseProps = {
  /** The current active sheets */
  sheets: Array<SheetObject | undefined>;

  /** The sheet element whose elevation should be used for 2D elements */
  sheetForElevation?: IElementGenericImgSheet;

  /** The current active point cloud */
  pointCloud: PointCloudObject | null;

  /** The current active CAD model */
  cadModel: CadModelObject | null;

  /** List of placeholders to show */
  panos: IElementImg360[];

  /** List of paths to show */
  paths: IElementSection[];

  /** Annotations to render */
  annotations: IElementGenericAnnotation[];

  /** The measurements in the store for the current visible objects */
  measurements: Measurement[];

  /** True if there's an active tool that requires picking */
  isPickingToolActive: boolean;

  /** Optional clipping planes */
  clippingPlanes?: Plane[];

  /** Whether the 3D models should be rendered on demand */
  renderOnDemand: boolean;

  /** Callback to signal a placeholder have been clicked*/
  onPlaceholderClick?(target: IElementImg360 | undefined): void;

  /** Signal the active model is hovered by the mouse */
  onModelHovered?(ev: ThreeEvent<DomEvent>, iElementId: GUID): void;

  /** Signal the user clicked the active model */
  onModelClicked?(ev: ThreeEvent<DomEvent>, iElementId: GUID): void;

  /** Signal the user zoomed the active model */
  onModelZoomed?(ev: ThreeEvent<DomEvent>, iElementId: GUID): void;

  /** The analyses in the store for the current visible point cloud */
  analyses: PointCloudAnalysis[];
};

/**
 * @returns A simple scene with the pointcloud/cad and the required lights
 */
export function OverviewBaseScene({
  sheets,
  sheetForElevation,
  pointCloud,
  cadModel,
  panos,
  paths,
  annotations,
  measurements,
  isPickingToolActive,
  clippingPlanes,
  renderOnDemand,
  onPlaceholderClick,
  onModelHovered,
  onModelClicked,
  onModelZoomed,
  analyses,
}: OverviewModeSceneBaseProps): JSX.Element {
  const shouldShowWaypointsOnFloor = useAppSelector(
    selectShouldShowWaypointsOnFloors,
  );

  const positions = useWalkPlaceholderPositions(
    panos,
    sheetForElevation,
    !shouldShowWaypointsOnFloor,
  );

  const { visiblePlaceholders, visiblePositions } = useVisiblePlaceholders({
    placeholders: panos,
    positions,
    clippingPlanes,
  });

  const { placeholdersOffset, shiftedPlaceholders } =
    useOffsetPlaceholders(visiblePositions);

  const waypoints = useWaypoints(visiblePlaceholders, visiblePositions);

  // Enable raycasting on models only if a tool is active, to enhance performance
  const activeTool = useAppSelector(selectActiveTool);

  const cadSelected = useAppSelector(selectOverviewIsCadSelected);

  const cloudSelected = useAppSelector(selectOverviewIsPointCloudSelected);

  const shouldWayPointsBeVisible = useAppSelector(
    selectObjectVisibility(ViewObjectTypes.waypoints),
  );

  useSceneContextMenuEventHandlers({
    pointCloud: cloudSelected && pointCloud ? pointCloud : undefined,
    cadModel: cadSelected && cadModel ? cadModel : undefined,
  });

  const analysesVisible = useAppSelector(
    selectObjectVisibility(ViewObjectTypes.analyses),
  );
  const showAnalyses = analysesVisible && analyses.length > 0;

  const shouldDisplayWaypointLabels = useAppSelector(
    selectObjectVisibility(ViewObjectTypes.waypointLabels),
  );

  return (
    <>
      <UniformLights />
      {/* A model is inserted in the scene only if the corresponding option is selected.
       * This has the effect that, when transitioning e.g. from overview to walk mode,
       * the user sees only the selected model and not all three of them. */}
      {cadSelected && cadModel && (
        <CadRenderer
          cadModel={cadModel}
          onPointerMove={
            activeTool
              ? (ev) => onModelHovered?.(ev, cadModel.iElement.id)
              : undefined
          }
          onClick={
            activeTool
              ? (ev) => onModelClicked?.(ev, cadModel.iElement.id)
              : undefined
          }
          onWheel={
            activeTool
              ? (ev) => onModelZoomed?.(ev, cadModel.iElement.id)
              : undefined
          }
          clippingPlanes={clippingPlanes}
        />
      )}
      {cloudSelected && pointCloud && (
        <PointCloudRenderer
          pointCloud={pointCloud}
          onPointerMove={
            activeTool
              ? (ev) => onModelHovered?.(ev, pointCloud.iElement.id)
              : undefined
          }
          onClick={
            activeTool
              ? (ev) => onModelClicked?.(ev, pointCloud.iElement.id)
              : undefined
          }
          onWheel={
            activeTool
              ? (ev) => onModelZoomed?.(ev, pointCloud.iElement.id)
              : undefined
          }
          clippingPlanes={clippingPlanes}
        />
      )}
      {sheets.map(
        (sheet) =>
          sheet && (
            <SheetRenderer
              sheet={sheet}
              clippingPlanes={clippingPlanes}
              backgroundTransparent={FloorPlanBackgroundTransparency.Alpha}
              key={sheet.iElement.id}
            />
          ),
      )}
      {!isPickingToolActive && (
        <>
          <AnnotationsRenderer
            annotations={annotations}
            clippingPlanes={clippingPlanes}
          />
          <WalkPathsRenderer
            paths={paths}
            sheetForElevation={sheetForElevation}
            setActiveElement={onPlaceholderClick}
            clippingPlanes={clippingPlanes}
          />
          <group position={placeholdersOffset}>
            <OverviewWaypointsRenderer
              visible={shouldWayPointsBeVisible}
              waypoints={shiftedPlaceholders}
              shouldFaceCamera={!shouldShowWaypointsOnFloor}
              onWaypointClicked={(index) => {
                Analytics.track(EventType.selectLocation, {
                  via: SelectLocationProperties.model3dView,
                });

                onPlaceholderClick?.(visiblePlaceholders[index]);
              }}
            />
          </group>
          {
            // Render waypoint labels
            shouldWayPointsBeVisible && shouldDisplayWaypointLabels && (
              <WaypointLabelRender
                waypoints={waypoints}
                onLabelClick={onPlaceholderClick}
              />
            )
          }
        </>
      )}
      <MeasurementsRenderer
        measurements={measurements}
        isPickingToolActive={isPickingToolActive}
        clippingPlanes={clippingPlanes}
      />
      {pointCloud && showAnalyses && (
        <AnalysesRenderer
          analyses={analyses}
          pointCloud={pointCloud}
          clippingPlanes={clippingPlanes}
        />
      )}
      <OverviewRenderingPipeline
        pointCloud={pointCloud}
        cadModel={cadModel}
        cadSelected={cadSelected}
        cloudSelected={cloudSelected}
        renderOnDemand={renderOnDemand}
      />
    </>
  );
}
