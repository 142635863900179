import { type RuntimeConfig } from "@/runtime-config";
import {
  CUSTOM_ENV,
  FORCE_LOCALIZE_SAVE_STRINGS,
  PREVIEW_FEATURES,
} from "./env-constants";
import {
  getDevDeploymentTypeFromHostname,
  getEnvAndRegionFromOrigin,
  getEnvAndRegionFromString,
} from "./env-utils";

/** Intercom App Id to use in the development environments */
const INTERCOM_DEV_ID = "ufxujusd";

// TODO: Release intercom in production https://faro01.atlassian.net/browse/SWEB-3735
/** Intercom App Id to use in the production environments */
// eslint-disable-next-line unused-imports/no-unused-vars
const INTERCOM_PROD_ID = "cuydqq3r";

/** Amplitude Id to use in the development environments */
const AMPLITUDE_DEV_ID = "45ce3106961603ef854ff9c97da547a7";

/** Amplitude Id to use in the production environments */
const AMPLITUDE_PROD_ID = "1bb4e3569734dcefcabada48747bbb2c";

/** Localize project key to use in all environments */
const LOCALIZE_PROJECT_KEY = "urMdSmZUoM3xK";

/**
 * Parses the current environment and origin to compute all the runtime config
 * to use for this request
 *
 * @param override env string to use to override the env defined in the url
 * @returns the runtime config to use
 */
export function loadRuntimeConfig(override = CUSTOM_ENV): RuntimeConfig {
  const { origin, hostname } = location;
  const [env, region] = override
    ? getEnvAndRegionFromString(override)
    : getEnvAndRegionFromOrigin(origin);

  const { isLocalhost, isDeployedDevEnvironment } =
    getDevDeploymentTypeFromHostname(hostname);

  return {
    deployment: {
      topLevelDomain: origin,
      environment: `${env}:${region}`,
    },
    authentication: {
      ulmUrl: envUrl`https://entry${env}.holobuilder${region}`,
    },
    externalLinks: {
      alignmentTool: `${origin}/alignment`,
      contactUrl: "https://www.faro.com/About-Us/Contact-Us",
      cookieManagerLatestUrl:
        region === "eu"
          ? undefined
          : envUrl`https://cookies${env}.holobuilder${region}/cookie-manager/manager/latest`,
      cookieManagerPreflyUrl:
        region === "eu"
          ? undefined
          : envUrl`https://cookies${env}.holobuilder${region}/cookie-manager/prefly/latest`,
      cookiePolicyUrl: "https://www.farosphere.com/home/legal/cookie-policy",
      dashboardUrl: envUrl`https://dashboard${env}.holobuilder${region}`,
      dataProcessingAgreementUrl:
        "https://www.farosphere.com/home/legal/data-processing-agreement",
      feedbackForm: "https://forms.office.com/r/UPcrz4wJQM",
      getEarlyAccessForm: "https://forms.office.com/r/BT0w2sDR4E",
      holoBuilderHome: "https://www.holobuilder.com",
      imprintUrl: "https://www.faro.com/de-DE/Imprint",
      jiraCollector:
        "https://faro01.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/1jmxwi/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=6c574e47",
      privacyPolicyUrl: "https://www.holobuilder.com/privacy-policy-docs/",
      staticResourcesBaseUrl:
        "https://uploads.holobuilder.com/static/media/viewer/",
      termsOfServiceUrl: "https://www.holobuilder.com/terms-and-conditions/",
      troubleShooting:
        "https://help.holobuilder.com/en/collections/3812895-sphere-viewer",
      unsupportedProjectsForm:
        "https://forms.office.com/pages/responsepage.aspx?id=y8SdpyRqR06NtMe9dG4jt3RpddcAhfZKltQGLe-vUzdUQ0ZXS0tPWkdHTTI0VkxBMUdIN0FBUzRFVS4u",
      webEditor: envUrl`https://app${env}.holobuilder${region}/app`,
    },
    externalServices: {
      // TODO: Expose intercom in production https://faro01.atlassian.net/browse/SWEB-3735
      intercom: env === "prod" ? undefined : { appId: INTERCOM_DEV_ID },
      localize: {
        projectKey: LOCALIZE_PROJECT_KEY,
        // Allowing dev to publish new strings would mean that, every test made locally, would add strings to the dictionary,
        // even if that feature is temporary.
        // Allowing production to publish strings, would be an issue if there is a bug in the code,
        // because that would add production user data to the dictionary
        // Allowing only staging reduces both those risks, but it does add the risk that some strings might be missed
        // in the dictionary if a feature is never used in staging before is released to production.
        // But testing staging before production should catch that.
        saveNewTranslations:
          FORCE_LOCALIZE_SAVE_STRINGS ||
          (!isLocalhost && !isDeployedDevEnvironment && env === "staging"),
      },
    },
    backendEndpoints: {
      coreApiUrl: envUrl`https://core.api${env}.holobuilder${region}`,
      pointCloudApiUrl: envUrl`https://pointcloud.api${env}.holobuilder${region}`,
      preAlignmentApiUrl: envUrl`https://pre-alignment.api${env}.holobuilder${region}`,
      progressApiUrl: envUrl`https://progress.api${env}.holobuilder${region}`,
      projectApiUrl: envUrl`https://v2.project.api${env}.holobuilder${region}`,
      registrationApiUrl: envUrl`https://cloud-reg.api${env}.holobuilder${region}`,
      bcfServicesApiUrl: envUrl`https://cde.api${env}.holobuilder${region}`,
      sentryDsn:
        "https://3fd09be9d1dc432abe053d8bfb57b4ba@o381590.ingest.sentry.io/4504366532591616",
    },
    analytics: {
      amplitudeApiKey: env === "prod" ? AMPLITUDE_PROD_ID : AMPLITUDE_DEV_ID,
    },
    enablePreviewFeatures: PREVIEW_FEATURES ?? env === "dev",
  };
}

/**
 * Format a template url injecting the env and region
 *
 * This template logic is special because the "prod" environment should be replaced by an empty string
 * and the other values should be prefixed with a dot "."
 *
 * @param tokens the template string tokens
 * @param params the params to inject into the template
 * @returns the formatted url
 */
function envUrl(tokens: TemplateStringsArray, ...params: string[]): string {
  const result: string[] = [];
  for (const [index, param] of params.entries()) {
    result.push(tokens[index]);
    if (param !== "prod") {
      result.push(`.${param}`);
    }
  }
  result.push(tokens.at(-1) ?? "");
  return result.join("");
}
