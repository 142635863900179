import { clearStore } from "@faro-lotv/app-component-toolbox";
import { GUID } from "@faro-lotv/ielement-types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/**
 * Shared state of the active elements of the project
 */
export type SelectionState = {
  /** The current active element */
  activeElement?: GUID;

  /** The current active area */
  activeArea?: GUID;

  /** The list of current active sheets; empty is invalid */
  activeSheets: GUID[];

  /**
   * The list of user visible sheets/layers in the whole project, indexed by GUID.
   * A sheet not visible is imply not listed in this record; this will avoid this record to overgrow and have to contain all the sheets.
   * Undefined is the same as empty = no user visible layers.
   * Despite the rule for the app that at least one layer must be visible,
   * the store will not enforce this rule, and maybe none of the area's layers will be listed here.
   * Undefined value is only supported to simply initialize the store; this is the same as empty record.
   */
  visibleSheets?: Record<GUID, { isVisible: true }>;
};

const initialState: SelectionState = {
  activeElement: undefined,
  activeArea: undefined,
  activeSheets: [],
  visibleSheets: undefined,
};

/**
 * Payload for setSheetVisibility reducer.
 */
type SheetVisibilityProps = {
  /** The id of the sheet */
  sheetId: GUID;
  /** The new visibility state */
  isVisible: boolean;
};

/**
 * Slice to access global information about the current state of the active elements of the project
 */
const selectionsSlice = createSlice({
  name: "selection",
  initialState,
  reducers: {
    /**
     * Change the current active element of the app
     *
     * @param state Current state
     * @param action The id of the element
     */
    setActiveElement(state, action: PayloadAction<GUID | undefined>) {
      state.activeElement = action.payload;
    },

    /**
     * Change the current active area of the app
     *
     * @param state Current state
     * @param action The id of the area
     */
    setActiveArea(state, action: PayloadAction<GUID | undefined>) {
      state.activeArea = action.payload;
    },

    /**
     * Change the current list of active sheets of the app
     *
     * @param state Current state
     * @param action The list of sheet ids
     */
    setActiveSheets(state, action: PayloadAction<GUID[]>) {
      state.activeSheets = action.payload;
    },

    /**
     * Set a sheet/layer visibility.
     *
     * @param state Current state
     * @param action The id of the sheet, plus the new visibility state (true = visible, false = non visible)
     */
    setSheetVisibility(state, action: PayloadAction<SheetVisibilityProps>) {
      if (action.payload.isVisible) {
        state.visibleSheets = {
          ...state.visibleSheets,
          [action.payload.sheetId]: { isVisible: true },
        };
      } else {
        if (state.visibleSheets === undefined) {
          state.visibleSheets = {};
        }
        // remove the sheet from the list of visible sheets
        const newList: Record<GUID, { isVisible: true }> = {};
        for (const [key, value] of Object.entries(state.visibleSheets)) {
          if (key !== action.payload.sheetId) {
            newList[key] = value;
          }
        }
        state.visibleSheets = newList;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearStore, () => initialState);
  },
});

export const selectionsReducer = selectionsSlice.reducer;

export const {
  setActiveElement,
  setActiveArea,
  setActiveSheets,
  setSheetVisibility,
} = selectionsSlice.actions;
