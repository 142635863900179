import { useSceneEvents } from "@/components/common/scene-events-context";
import {
  CameraAnimation,
  CameraAnimationProps,
} from "@/components/r3f/animations/camera-animation";
import { useAppSelector } from "@/store/store-hooks";
import {
  selectIElementWorldPosition,
  useTypedEvent,
} from "@faro-lotv/app-component-toolbox";
import {
  IElementGenericImgSheet,
  isIElementGenericStream,
  isIElementImg360,
} from "@faro-lotv/ielement-types";
import { Quaternion as QuaternionProp } from "@react-three/fiber";
import { useState } from "react";
import { Matrix4, Object3D, Quaternion, Vector3 } from "three";
import { ModelToModel } from "./animations/model-to-model";
import { ModelToPano } from "./animations/model-to-pano";
import { PanoToModel } from "./animations/pano-to-model";
import { PanoToPano } from "./animations/pano-to-pano/pano-to-pano";
import { ViewType, WalkSceneActiveElement } from "./walk-types";

type WalkSceneAnimationProp = {
  /** The element from which we start the animation */
  currentElement: WalkSceneActiveElement;

  /** The target element of the animation */
  targetElement: WalkSceneActiveElement;

  /** if provided quaternion should be applied while performing pano animation */
  targetQuaternion?: QuaternionProp;

  /** sheet used to get the elevation of the pano */
  sheetForElevation?: IElementGenericImgSheet;

  /** Which view is this: main view in walk mode, left view, or right view in splitscreen mode */
  viewType: ViewType;

  /** Callback executed when the animation finishes */
  onAnimationFinished(target: WalkSceneActiveElement): void;

  /** Callback executed when the camera moves in a different position */
  onCameraMoved?(position: Vector3): void;
};

/** @returns The correct animation for the walk scene */
export function WalkSceneAnimation({
  onAnimationFinished,
  ...props
}: WalkSceneAnimationProp): JSX.Element {
  const [transitionFinished, setTransitionFinished] = useState(false);

  const targetPosition = useAppSelector(
    selectIElementWorldPosition(props.targetElement.id),
  );
  const [lookAtAnimation, setLookAtAnimation] =
    useState<CameraAnimationProps>();

  const { lookAt } = useSceneEvents();
  useTypedEvent(lookAt, (position: Vector3) => {
    const quaternion = new Quaternion().setFromRotationMatrix(
      new Matrix4().lookAt(
        new Vector3().fromArray(targetPosition),
        position,
        Object3D.DEFAULT_UP,
      ),
    );
    setLookAtAnimation({
      quaternion,
    });
  });

  return (
    <>
      <WalkSceneTransition
        {...props}
        onAnimationFinished={() => {
          setTransitionFinished(true);
          if (!lookAtAnimation) {
            onAnimationFinished(props.targetElement);
          }
        }}
      />
      {/** If there's a lookAt animation scheduled, wait for the main scene transition to end first */}
      {transitionFinished && lookAtAnimation && (
        <CameraAnimation
          {...lookAtAnimation}
          onAnimationFinished={() => {
            setTransitionFinished(false);
            setLookAtAnimation(undefined);
            onAnimationFinished(props.targetElement);
          }}
        />
      )}
    </>
  );
}

function WalkSceneTransition({
  currentElement,
  targetElement,
  sheetForElevation,
  viewType,
  onCameraMoved,
  targetQuaternion,
  onAnimationFinished,
}: WalkSceneAnimationProp): JSX.Element | null {
  // TODO: Ensure onCameraMoved is called, in the animation components, when the camera position changes https://faro01.atlassian.net/browse/SWEB-3349

  if (isIElementImg360(currentElement)) {
    if (isIElementImg360(targetElement)) {
      /** Current: Pano, Target: Pano */
      return (
        <PanoToPano
          // The key property is necessary so that the previous animation is
          // unmounted completely before mounting the new one
          key={targetElement.id}
          currentElement={currentElement}
          targetElement={targetElement}
          targetQuaternion={targetQuaternion}
          sheetForElevation={sheetForElevation}
          isSecondaryView={viewType === ViewType.RightView}
          onCameraMoved={onCameraMoved}
          onAnimationFinished={onAnimationFinished}
        />
      );
    }
    /** Current: Pano, Target: PointCloud/CAD */
    return (
      <PanoToModel
        currentElement={currentElement}
        targetElement={targetElement}
        sheetForElevation={sheetForElevation}
        onAnimationFinished={onAnimationFinished}
        onCameraMoved={onCameraMoved}
      />
    );
  } else if (isIElementGenericStream(currentElement)) {
    if (isIElementImg360(targetElement)) {
      /** Current: PointCloud, Target: Pano */
      return (
        <ModelToPano
          currentElement={currentElement}
          targetElement={targetElement}
          sheetForElevation={sheetForElevation}
          onCameraMoved={onCameraMoved}
          onAnimationFinished={onAnimationFinished}
          isSecondaryView={viewType === ViewType.RightView}
        />
      );
    }
    /** Current: PointCloud/CAD, Target: PointCloud */
    return (
      <ModelToModel
        targetElement={targetElement}
        onAnimationFinished={onAnimationFinished}
      />
    );
  }
  return null;
}
