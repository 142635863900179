import { useViewRuntimeContext } from "@/components/common/view-runtime-context";
import {
  CameraParameters,
  decodeCameraParameters,
  encodeCameraParameters,
  getCameraParameters,
} from "@/components/r3f/utils/camera-parameters";
import {
  InitialStateProps,
  ModeWithSceneFilterInitialState,
} from "@/modes/mode";
import { useCurrentArea, useCurrentScene } from "@/modes/mode-data-context";
import { CurrentScene, selectCurrentScene } from "@/modes/mode-selectors";
import { selectWalkSceneElements } from "@/modes/walk-mode/walk-scene-selectors";
import { WALK_MODE_INITIAL_STATE } from "@/modes/walk-mode/walk-state";
import { WalkSceneActiveElement } from "@/modes/walk-mode/walk-types";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import {
  selectCompareElement,
  selectWalkCompareSceneFilter,
  selectWalkMainSceneType,
} from "@/store/modes/walk-mode-slice";
import { useAppSelector } from "@/store/store-hooks";
import { SceneFilter } from "@/types/scene-filter";
import { GUID, assert, includes } from "@faro-lotv/foundation";
import { selectIElement } from "@faro-lotv/project-source";
import { isEqual } from "es-toolkit";
import { Vector3 } from "three";

/** The composed state for the split screen mode accounting for both views */
export type SplitState = {
  /** The scene definition for the main view */
  mainScene: CurrentScene;

  /** The scene filter in the main view */
  mainSceneFilter: SceneFilter;

  /** The main element to be rendered on left scene */
  mainWalkElement: WalkSceneActiveElement;

  /** The scene definition for the secondary view */
  compareScene: CurrentScene;

  /** The scene filter in the secondary view */
  compareSceneFilter: SceneFilter;

  /** The element to render on the right scene as comparison element */
  compareWalkElement: WalkSceneActiveElement;
};

/**
 * @returns the main and comparison element data for split screen interaction
 */
export function useSplitState(): SplitState {
  const area = useCurrentArea();
  const mainScene = useCurrentScene();

  const compareElement =
    useAppSelector(selectCompareElement) ?? mainScene.activeElement;

  assert(
    compareElement,
    "To start split screen we need a valid object to compare",
  );

  const layerVisibilityEnabled = useAppSelector(
    selectHasFeature(Features.LayersVisibility),
  );

  // Do not allow cad model in the compare scene as the rendering pipeline
  // is not yet able to render the cad model in two scenes at the same time
  const compareScene = useAppSelector(
    (state) =>
      selectCurrentScene(
        compareElement,
        undefined,
        layerVisibilityEnabled ? undefined : mainScene.activeSheets[0],
        area,
        state.walkMode.compare.compareShouldUseIntensityData,
      )(state),
    isEqual,
  );

  assert(
    compareScene,
    "A valid render object for both scenes is required for split mode",
  );

  const mainSceneFilter = useAppSelector(selectWalkMainSceneType);
  const compareSceneFilter = useAppSelector(selectWalkCompareSceneFilter);

  const { cameras } = useViewRuntimeContext();

  // Determine the walk elements for both scenes
  const [mainWalkElement] = useAppSelector(
    selectWalkSceneElements(
      mainScene,
      area,
      cameras[0]?.position ?? new Vector3(),
      mainSceneFilter,
    ),
    isEqual,
  );

  const [compareWalkElement] = useAppSelector(
    selectWalkSceneElements(
      compareScene,
      area,
      cameras[1]?.position ?? new Vector3(),
      compareSceneFilter,
    ),
    isEqual,
  );
  assert(
    mainWalkElement && compareWalkElement,
    "A valid render split screen both scenes should have a valid element",
  );

  return {
    mainScene,
    mainSceneFilter,
    mainWalkElement,
    compareScene,
    compareSceneFilter,
    compareWalkElement,
  };
}

export type SplitInitialState = ModeWithSceneFilterInitialState & {
  /** The id of the compare element */
  rightId: GUID;
  /** The camera parameters for the right screen */
  rightCam?: CameraParameters;
  /** The active scene filter */
  rightScene: SceneFilter;
};

/** An object to compute and parse a state object to create and restore the walk mode states from deep links */
export const SPLIT_MODE_INITIAL_STATE: InitialStateProps<SplitInitialState> = {
  compute(state, viewContext) {
    return {
      ...WALK_MODE_INITIAL_STATE.compute(state, viewContext),
      rightId: selectCompareElement(state)?.id ?? "",
      rightCam: encodeCameraParameters(
        getCameraParameters(viewContext.cameras[1]),
      ),
      rightScene: selectWalkCompareSceneFilter(state),
    };
  },
  parse(appState, modeState) {
    const walkState = WALK_MODE_INITIAL_STATE.parse(appState, modeState);
    const { rightId, rightCam } = modeState;

    if (!walkState || !rightId || typeof rightId !== "string") {
      return;
    }
    if (!selectIElement(rightId)(appState)) {
      throw new Error(
        "Invalid SplitMode deep link: comparison element id does not exist",
      );
    }

    const rightScene = includes(
      Object.values(SceneFilter),
      modeState.rightScene,
    )
      ? modeState.rightScene
      : SceneFilter.Pano;

    return {
      ...walkState,
      rightId,
      rightCam: rightCam ? decodeCameraParameters(rightCam) : undefined,
      rightScene,
    };
  },
};
