import { ElementIcon, iconForElement } from "@/components/ui/icons";
import { selectDefaultModeFor } from "@/modes/index";
import { ModeNames } from "@/modes/mode";
import { selectModeName } from "@/store/mode-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { Dropdown, ScanIcon, TruncatedFaroText } from "@faro-lotv/flat-ui";
import {
  GUID,
  IElement,
  IElementBase,
  IElementType,
} from "@faro-lotv/ielement-types";
import {
  newestToOldest,
  selectIElementChildren,
} from "@faro-lotv/project-source";
import { SelectChangeEvent, Stack, SxProps, Theme } from "@mui/material";
import { isEqual } from "es-toolkit";
import { useCallback, useMemo, useState } from "react";

/** Style to apply to the icons in the dropdown */
const ICON_LABEL_SX: SxProps<Theme> = {
  width: "18px",
  height: "18px",
  lineHeight: 0,
};

function IconLabelForElement({
  iElement,
}: Pick<TimestampDropdownOptionLabelProps, "iElement">): JSX.Element {
  const childrenTypeHints = useAppSelector(
    selectIElementChildren(iElement.id),
    isEqual,
  ).map((el: IElementBase) => el.typeHint);

  // Specific icon to display for the current node
  const icon = useMemo(
    () => iconForElement(iElement.type, iElement.typeHint, childrenTypeHints),
    [iElement.type, iElement.typeHint, childrenTypeHints],
  );

  // Use specific icon for the scans
  if (iElement.type === IElementType.img360) {
    return <ScanIcon sx={ICON_LABEL_SX} />;
  }

  return <ElementIcon icon={icon} sx={ICON_LABEL_SX} />;
}

export type TimestampDropdownOptionLabelProps = {
  /** an IElement whose label needs to be shown in the dropdown */
  iElement: IElement;

  /** a formatter function which lets the label to be customised */
  labelFormatter(iElement: IElement): JSX.Element | string;

  /** Custom styles for the label */
  sx?: SxProps<Theme>;
};

/**
 * @returns a label with the element icon, name and timestamp
 */
export function TimestampDropdownOptionLabel({
  iElement,
  labelFormatter,
  sx,
}: TimestampDropdownOptionLabelProps): JSX.Element {
  return (
    <Stack component="span" direction="row" alignItems="center" sx={sx} gap={1}>
      <IconLabelForElement iElement={iElement} />
      <TruncatedFaroText
        variant="bodyM"
        sx={{
          textWrap: "nowrap",
          color: "currentcolor",
        }}
      >
        {labelFormatter(iElement)}
      </TruncatedFaroText>
    </Stack>
  );
}

type TimestampDropdownProps = {
  /** ID of the selected option */
  value: GUID;

  /** List of options */
  options: IElement[];

  /** Custom function to format the label as required */
  labelFormatter(iElement: IElement): JSX.Element | string;

  /** List of id of the elements to disable in the drop-down menu */
  disabledElements?: GUID[];

  /** Callback to signal a new option was selected */
  onChange(ev: SelectChangeEvent<GUID>): void;
};

/**
 * @returns a dropdown component for timestamp selections
 */
export function TimestampDropdown({
  value,
  options,
  labelFormatter,
  disabledElements = [],
  onChange: onChangeOption,
}: TimestampDropdownProps): JSX.Element {
  const activeMode = useAppSelector(selectModeName);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Sort the options from newest to oldest
  const sortedOptions = useMemo(() => options.sort(newestToOldest), [options]);

  const onClose = useCallback(() => {
    setIsDropdownOpen(false);
  }, [setIsDropdownOpen]);

  const onOpen = useCallback(() => {
    setIsDropdownOpen(true);
  }, [setIsDropdownOpen]);

  const onChange = useCallback(
    (ev: SelectChangeEvent<GUID>) => {
      onChangeOption(ev);
      setIsDropdownOpen(false);
    },
    [onChangeOption],
  );

  // Compute the default mode for each value in the dropdown
  const modeComputedOptions: Array<IElement & { mode: ModeNames | undefined }> =
    useAppSelector(
      (state) =>
        sortedOptions.map((option) => ({
          ...option,
          mode: selectDefaultModeFor(option)(state)?.targetMode,
        })),
      (oldValue, newValue) => isEqual(oldValue, newValue),
    );

  return (
    <Dropdown
      dark
      value={value}
      onClose={onClose}
      onOpen={onOpen}
      variant="underlined"
      disabled={options.length < 2}
      onChange={onChange}
      options={modeComputedOptions.map((option) => ({
        key: option.id,
        value: option.id,
        label: (
          <TimestampDropdownOptionLabel
            iElement={option}
            labelFormatter={labelFormatter}
          />
        ),
        isDisabled: !option.mode || disabledElements.includes(option.id),
      }))}
      sx={{
        maxWidth: activeMode === "split" ? "150px" : "250px",
        ".MuiSelect-outlined.MuiOutlinedInput-input": {
          fontSize: "1em",
          p: 1.5,
          pr: 4,
        },
        // Don't show line under the selected option, in the idle state
        ...(isDropdownOpen ? {} : { boxShadow: "none" }),
      }}
    />
  );
}
