import { SheetModeControls } from "@/components/r3f/controls/sheet-mode-controls";
import { AnnotationsRenderer } from "@/components/r3f/renderers/annotations/annotations-renderer";
import { DesaturationSheetsPipeline } from "@/components/r3f/renderers/desaturation-pipeline";
import { MeasurementsRenderer } from "@/components/r3f/renderers/measurements/measurements-renderer";
import { OdometryPathsRenderer } from "@/components/r3f/renderers/odometry-paths/odometry-paths-renderer";
import { SheetRenderer } from "@/components/r3f/renderers/sheet-renderer";
import { PlaceholderPreview } from "@/components/r3f/utils/placeholder-preview";
import { SheetObject } from "@/object-cache";
import { Measurement } from "@/store/measurement-tool-slice";
import { selectModeIsTransitioning } from "@/store/mode-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { ToolName } from "@/store/ui/ui-slice";
import { PickingToolsCallbacks } from "@/tools/use-picking-tools-callbacks";
import {
  selectIElementWorldPosition,
  useOnClick,
} from "@faro-lotv/app-component-toolbox";
import {
  IElement,
  IElementGenericAnnotation,
  IElementGenericImgSheet,
  IElementImg360,
  IElementSection,
} from "@faro-lotv/ielement-types";
import { FloorPlanBackgroundTransparency } from "@faro-lotv/lotv";
import { ThreeEvent, useThree } from "@react-three/fiber";
import { useMemo, useState } from "react";
import { Box3, Plane, Vector3 } from "three";
import { SheetModeRenderOrders } from "./sheet-mode-render-orders";
import { SheetWaypoints } from "./sheet-waypoints";

type SheetModeSceneBaseProps = {
  /** The current active sheets */
  sheets: Array<SheetObject | undefined>;

  /** Sheet whose elevation will be used for paths and 2D items */
  sheetElementForElevation?: IElementGenericImgSheet;

  /** The current active path */
  pathElement?: IElement;

  /** The list of paths to render */
  paths: IElementSection[];

  /** The list of panoramas currently showed */
  panos: IElementImg360[];

  /** The list of annotations to render on the map */
  annotations?: IElementGenericAnnotation[];

  /** The list of measurements from the store, to render on the map */
  measurements?: Measurement[];

  /** Optional clipping planes to clip the scene with */
  clippingPlanes?: Plane[];

  /** Flag to make the sheet transparent */
  transparentSheet?: boolean;

  /** Callback when a placeholder is clicked */
  onPlaceholderClicked?(target: IElementImg360): void;

  /** Callback on path activated */
  onPathActivated?(
    ev: ThreeEvent<MouseEvent>,
    path: IElementSection,
    boundingBox: Box3,
  ): void;

  /** Callback when the sheet is clicked which gives the clicked position */
  onSheetClick?(pos: Vector3): void;

  /** Boolean to indicate if the sheet scene is used in minimap */
  isInMinimap?: boolean;

  /** Picking tool callback handlers to manage sheet interactions*/
  pickingToolsCallbacks?: PickingToolsCallbacks;
};

/**
 * @returns the basic scene to render in sheet mode
 */
export function SheetModeSceneBase({
  sheets,
  sheetElementForElevation,
  pathElement,
  paths,
  panos,
  annotations,
  measurements,
  clippingPlanes,
  onPlaceholderClicked,
  onPathActivated,
  onSheetClick,
  isInMinimap = false,
  transparentSheet,
  pickingToolsCallbacks,
}: SheetModeSceneBaseProps): JSX.Element {
  const camera = useThree((s) => s.camera);

  const isTransitioning = useAppSelector(selectModeIsTransitioning);

  const position = useAppSelector(
    selectIElementWorldPosition(sheetElementForElevation?.id),
  );

  const activeTool = useAppSelector(selectActiveTool);

  const [hoveredPlaceholder, setHoveredPlaceholder] =
    useState<IElementImg360>();

  const { onPointerDown, onClick } = useOnClick(
    (e) => onSheetClick?.(e.point),
    true,
  );

  const backgroundTransparent = useMemo(
    () =>
      sheets.length > 1
        ? FloorPlanBackgroundTransparency.Alpha
        : FloorPlanBackgroundTransparency.No,
    [sheets.length],
  );

  return (
    <>
      {sheets.map(
        (sheet) =>
          sheet && (
            <SheetRenderer
              sheet={sheet}
              transparent={transparentSheet}
              backgroundTransparent={backgroundTransparent}
              onPointerMove={
                activeTool && pickingToolsCallbacks
                  ? (ev) =>
                      pickingToolsCallbacks.onModelHovered(
                        ev,
                        sheet.iElement.id,
                      )
                  : undefined
              }
              onPointerDown={activeTool ? undefined : onPointerDown}
              onClick={
                activeTool && pickingToolsCallbacks
                  ? (ev) =>
                      pickingToolsCallbacks.onModelClicked(
                        ev,
                        sheet.iElement.id,
                      )
                  : onClick
              }
              onWheel={
                activeTool && pickingToolsCallbacks
                  ? (ev) =>
                      pickingToolsCallbacks.onModelZoomed(ev, sheet.iElement.id)
                  : undefined
              }
              key={sheet.iElement.id}
            />
          ),
      )}
      <SheetModeControls camera={camera} referencePlaneHeight={position[1]} />
      {!isTransitioning && !pickingToolsCallbacks && (
        <>
          <OdometryPathsRenderer
            paths={paths}
            sheetForElevation={sheetElementForElevation}
            activePath={pathElement}
            onPlaceholderHovered={setHoveredPlaceholder}
            onPlaceholderClicked={onPlaceholderClicked}
            onPathClick={onPathActivated}
            clippingPlanes={clippingPlanes}
          />
          {!isInMinimap && (
            <PlaceholderPreview placeholder={hoveredPlaceholder} />
          )}
          {annotations && (
            <AnnotationsRenderer
              annotations={annotations}
              depthTest={false}
              renderOrder={SheetModeRenderOrders.MeasurementsAndAnnotations}
              fadeOff={false}
              clippingPlanes={clippingPlanes}
            />
          )}
          <SheetWaypoints
            paths={paths}
            panos={panos}
            sheetForElevation={sheetElementForElevation}
            clippingPlanes={clippingPlanes}
            onPlaceholderClicked={onPlaceholderClicked}
            onPlaceholderHovered={setHoveredPlaceholder}
            onlyShowLabelOnHover={isInMinimap}
          />
        </>
      )}
      {!isTransitioning && measurements && (
        <MeasurementsRenderer
          measurements={measurements}
          isPickingToolActive={!!pickingToolsCallbacks}
          depthTest={false}
          renderOrder={SheetModeRenderOrders.MeasurementsAndAnnotations}
          clippingPlanes={clippingPlanes}
        />
      )}

      {/*
       * The enabled flag from DesaturationPipeline is not used here so that the components using SheetModeSceneBase
       * need not have an instance of a EffectPipeline when there is no desaturateSheet tool in them
       *
       * Having this EffectPipeline along with another one in a R3f canvas or in a View
       * using SheetModeSceneBase would conflict with each other
       */}
      <DesaturationSheetsPipeline
        enabled={activeTool === ToolName.desaturateSheet}
      />
    </>
  );
}
