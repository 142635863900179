import { useAppSelector } from "@/store/store-hooks";
import {
  OrthoFrustum,
  computeOrthoFrustum,
  parseVector3,
  selectIElementWorldTransform,
  selectIElementsWorldPosition,
} from "@faro-lotv/app-component-toolbox";
import {
  IElementGenericImgSheet,
  IElementImg360,
} from "@faro-lotv/ielement-types";
import { OrientedBoundingBox } from "@faro-lotv/lotv";
import { Z_TO_Y_UP_QUAT } from "@faro-lotv/project-source";
import { Vector3 as Vector3Prop, useThree } from "@react-three/fiber";
import { isEqual } from "es-toolkit";
import { useMemo } from "react";
import {
  Box3,
  Matrix4,
  OrthographicCamera,
  Quaternion,
  Vector3,
  Vector3Tuple,
  Vector4,
} from "three";
import { useSheetCorners } from "./use-sheet-corners";

/** Information needed to center a camera on a set of placeholders */
export type CameraCenterData = {
  /** The camera position */
  position: Vector3;

  /** The camera quaternion */
  quaternion: Quaternion;

  /** The Orthographic frustum to make all placeholders visible */
  frustum: OrthoFrustum;
};

/**
 * Update a camera so it will frame a scene based on the centering data passed
 *
 * @param camera The ortho camera we want to update
 * @param data The data about the scene we want to frame
 */
export function centerOrthoCamera(
  camera: OrthographicCamera,
  data: CameraCenterData,
): void {
  camera.position.copy(data.position);
  camera.quaternion.copy(data.quaternion);
  // As we assign the frustrum we need to put manual to true
  Object.assign(camera, { ...data.frustum, manual: true });
  camera.updateProjectionMatrix();
}

export type CenterCameraOnPlaceholdersProps = {
  /** The area for which we want to compute the camera configuration. If provided, the camera will try to frame the entire area. */
  areaVolume?: OrientedBoundingBox;

  /** The sheet for which we want to compute the camera configuration */
  sheetElement?: IElementGenericImgSheet;

  /** All the placeholders */
  placeholders: IElementImg360[];

  /** aspect ration for view (if not provided full view will be used) */
  viewAspectRatio?: number;

  /**
   * Factor to modify the zoom of the camera relative to the bounds of @see cameraAlignedWaypoints
   * by default Add a 30% padding so that all elements are properly visible.
   */
  paddingFactor?: number;

  /**  An optional camera position to use instead of centering the placeholders */
  cameraPosition?: Vector3Prop;

  /** an optional parameter to express the minimum frustum height that the ortho camera should have  */
  minFrustumHeight?: number;
};

/**
 * Compute the correct position and frustum to visualize a set of objects on an ortho camera.
 * The centering will be attempted in this order:
 *
 * 1. If an areaVolume is provided, the camera will frame its volume bounds as defined in the iElement tree.
 * 2. If there are waypoints on the sheet, the camera will try to frame them.
 * 3. The camera will try to frame the sheet.
 * 4. If none of the above are available, the camera will center around the origin.
 *
 * @returns the data needed to center the camera
 */
export function useCenterCameraOnPlaceholders({
  areaVolume,
  sheetElement,
  placeholders,
  viewAspectRatio,
  paddingFactor = 1.3,
  cameraPosition,
  minFrustumHeight,
}: CenterCameraOnPlaceholdersProps): CameraCenterData {
  const { quaternion: sheetQuaternion } = useAppSelector(
    selectIElementWorldTransform(sheetElement?.id),
  );

  // Get the id of each panorama
  const ids = placeholders.map((iElement) => iElement.id);

  // Compute the quaternion we want for the camera so we look down on Y
  // but rotating the camera so the sheet is straight
  const cameraRotation = useMemo(
    () =>
      sheetElement
        ? new Quaternion().fromArray(sheetQuaternion)
        : Z_TO_Y_UP_QUAT.clone(),
    [sheetElement, sheetQuaternion],
  );

  // Collect the area's volume's corners to use for camera alignment
  const areaVolumeCornersWorld = useMemo(() => {
    if (areaVolume) {
      const areaVolumeMatrix = new Matrix4().compose(
        areaVolume.position,
        areaVolume.quaternion,
        areaVolume.size,
      );

      // Add the min and max corners of the volume to cover the entire area
      return [
        new Vector3(0.5, 0.5, 0.5).applyMatrix4(areaVolumeMatrix).toArray(),
        new Vector3(-0.5, -0.5, -0.5).applyMatrix4(areaVolumeMatrix).toArray(),
      ];
    }

    return [];
  }, [areaVolume]);

  const cameraAlignedAreaVolumeCorners = useCameraAlignedPoints(
    areaVolumeCornersWorld,
    cameraRotation,
  );

  // Collect the waypoints/scan locations to use for camera alignment
  const cameraAlignedWaypoints = useCameraAlignedPoints(
    useAppSelector(selectIElementsWorldPosition(ids), isEqual),
    cameraRotation,
  );

  const waypointsBox = useMemo(() => {
    const TEMP_P = new Vector3();
    const box = new Box3();
    cameraAlignedWaypoints.forEach((p) =>
      box.expandByPoint(TEMP_P.set(p[0], p[1], p[2])),
    );
    return box;
  }, [cameraAlignedWaypoints]);

  const hasValidWaypointsBox =
    cameraAlignedWaypoints.length > 0 &&
    waypointsBox.max.x - waypointsBox.min.x > Number.EPSILON &&
    waypointsBox.max.z - waypointsBox.min.z > Number.EPSILON;

  // Collect the corners of the sheet to use for alignment
  const sheetElements = useMemo(
    () => (sheetElement ? [sheetElement] : []),
    [sheetElement],
  );
  const sheetCornersWorld = useSheetCorners(sheetElements);
  const cameraAlignedSheetCorners = useCameraAlignedPoints(
    sheetCornersWorld,
    cameraRotation,
  );

  // Get screen aspect ratio
  const aspectRatio = useThree((state) => {
    if (viewAspectRatio) return viewAspectRatio;

    const viewport = state.gl.getViewport(new Vector4());
    return viewport.width / viewport.height;
  });

  const orthoFrustumPoints = useMemo(() => {
    if (areaVolume) {
      return cameraAlignedAreaVolumeCorners;
    } else if (hasValidWaypointsBox) {
      // Do not use the tiled img sheet corners if there are scans available
      // as they are not representative of the area of interest due to noise and the tiling algorithm
      return cameraAlignedWaypoints;
    } else if (cameraAlignedSheetCorners.length) {
      return cameraAlignedSheetCorners;
    }

    // If there are no other options center around the origin
    return [[0, 0, 0]];
  }, [
    areaVolume,
    cameraAlignedAreaVolumeCorners,
    cameraAlignedSheetCorners,
    cameraAlignedWaypoints,
    hasValidWaypointsBox,
  ]);

  /** Y-axis offset for the camera position */
  const Y_OFFSET = 100;
  const orthoFrustum = computeOrthoFrustum(
    orthoFrustumPoints,
    aspectRatio,
    paddingFactor,
  );

  if (
    minFrustumHeight &&
    minFrustumHeight > orthoFrustum.top - orthoFrustum.bottom
  ) {
    // The computation below ensures that the ortho frustum vertical size measures
    // exactly 'minFrustumHeight', while keeping the aspect ratio that the ortho
    // frustum already has.
    const adjust = minFrustumHeight / (orthoFrustum.top - orthoFrustum.bottom);
    orthoFrustum.top *= adjust;
    orthoFrustum.bottom *= adjust;
    orthoFrustum.left *= adjust;
    orthoFrustum.right *= adjust;
  }

  const position = cameraPosition
    ? parseVector3(cameraPosition)
    : orthoFrustum.bboxCenter
        .clone()
        .applyQuaternion(Z_TO_Y_UP_QUAT)
        .applyQuaternion(cameraRotation);
  position.y += Y_OFFSET;

  return {
    position,
    frustum: orthoFrustum,
    quaternion: cameraRotation,
  };
}

const TEMP_VECTOR = new Vector3();

/**
 * @returns points aligned to the camera rotation, but returned in a Z-up system
 * @param points world space points to rotate
 * @param cameraRotation the rotation of the camera
 */
function useCameraAlignedPoints(
  points: Vector3Tuple[],
  cameraRotation: Quaternion,
): Vector3Tuple[] {
  return useMemo(
    () =>
      points.map((v) =>
        TEMP_VECTOR.fromArray(v)
          .applyQuaternion(cameraRotation.clone().invert())
          .applyQuaternion(Z_TO_Y_UP_QUAT.clone().invert())
          .toArray(),
      ),
    [cameraRotation, points],
  );
}
