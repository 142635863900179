import {
  EventType,
  ToggleRegistrationViewSettingsProperties,
} from "@/analytics/analytics-events";
import { ToolButton, ViewOptionsIcon } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { PopoverProps, Tooltip } from "@mui/material";
import { useState } from "react";
import { DataPreparationViewSettingsMenu } from "./data-preparation-view-settings-menu";

export type ViewOptionsToolProps = {
  /** Reference to the element to anchor the popover to */
  anchorEl: PopoverProps["anchorEl"];
};

/** @returns a tool button for the ViewSettingsToolbar to manage the data-preparation ViewSettings */
export function DataPreparationViewSettingsTool({
  anchorEl,
}: ViewOptionsToolProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Tooltip placement="left" title="View settings">
        <ToolButton
          onClick={() => {
            Analytics.track<ToggleRegistrationViewSettingsProperties>(
              EventType.toggleRegistrationViewSettings,
              {
                opening: true,
              },
            );
            setIsOpen(true);
          }}
          selected={isOpen}
        >
          <ViewOptionsIcon />
        </ToolButton>
      </Tooltip>

      <DataPreparationViewSettingsMenu
        open={isOpen}
        onClose={() => {
          Analytics.track<ToggleRegistrationViewSettingsProperties>(
            EventType.toggleRegistrationViewSettings,
            {
              opening: false,
            },
          );
          setIsOpen(false);
        }}
        anchorEl={anchorEl}
      />
    </>
  );
}
